import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Type.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";

function Type() {
  const navigate = useNavigate
  const [Type, setType] = useState('');
 

  const [types, setTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');
 
  const resetForm = () => {
    setType('');
    
    setIsModalOpen(false);
  };


  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true)
    setIsEditing(false); // Ensure this is set to false
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!Type) {
      formErrors.Type = "Type is required.";
      isValid = false;
    }

   
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    const data = { 
    Type: Type
     };
  
    // Check if editing and set the appropriate URL
    const url = isEditing
      ? "https://peapi.microtechsolutions.co.in/php/setbatchtyp.php"
      : "https://peapi.microtechsolutions.co.in/php/postbatchtyp.php";
  
    // Only add Id when editing
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Type updated successfully!');
      } else {
        toast.success('Type added successfully!');
      }
      resetForm();
      fetchBatchtype(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
 
   
 
   
 
 
 
   
 
   useEffect(() => {
    
    fetchBatchtype();
   }, [])
 
  
  
   
  
 
   


   const fetchBatchtype = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchtype");
      setTypes(response.data);
    } catch (error) {
      toast.error("Error fetching types:", error);
    }
  };


  const handleEdit = (row) => {
    const type = types[row.index];
   setType(type.Type);
 
   
    setEditingIndex(row.index);
    setId(type.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    const typeToDelete = types[index]; 
    const id = typeToDelete.Id; 
    console.log("Deleting Type with ID:", id);
  
    try {
      const response = await axios.post(`https://peapi.microtechsolutions.co.in/php/delete.php?Table=Batchtype&Id=${id}`);
      console.log("Delete response:", response.data); // Log the response
  
      const newTypes = types.filter((_, i) => i !== index);
      setTypes(newTypes);
      
      toast.success("Type Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting Type:", error);
      toast.error("Error deleting Type. Please try again.");
    }
  };
  

   const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "Type",
      header: "Type",
      size: 50,
    },
   
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#012144",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [types]);

  const table = useMaterialReactTable({ columns, data: types,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });
   
 
   
 

  return (



    <div className="type-container">
    <h1>Type</h1>
    <div className="typetable-master">

   
      <div className="typetable1-master">
      <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",display:'flex',
             fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}
        >
          New
        </Button>
        <div className="typetable-container">
       
          <MaterialReactTable table={table} />
        </div>
      </div>

      {isModalOpen && <div className="type-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="type-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}
          >
            {editingIndex >= 0 ? "Edit Type " : "Add Type "}
          </h1>

          <form>


          <div className="type-form">
        
          <div>
              <label className="type-label">
              Type:<b className="required">*</b>
              </label>
              <div>
              <input
type="text"
 id="Type" 
 name="Type" 
 value={Type}
                  onChange={(e) => setType(e.target.value)}
                  className="type-control"
                  placeholder="Enter Type Name"
                />

<div>
                    {errors.Type && <b className="error-text">{errors.Type}</b>}
                  </div>
              </div>
            </div>


           
           

         
           
           
          
        </div></form>
          <div className="type-btn-container">
            <Button
              type="submit"
              style={{
                background: "#012144",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {editingIndex >= 0 ? "Update" : "Save"}
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                background: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    <ToastContainer />

    <footer className="footer">
      <small>&copy; Microtech, 2023. All rights reserved.</small>
    </footer>
  </div>


  );
}

export default Type;
