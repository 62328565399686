import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { MaterialReactTable,useMaterialReactTable } from "material-react-table";
import { Modal, Button, Typography } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import "./RegistrationList.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function RegistrationList() {
  const [Data, setData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch enquiry list
  const fetchStudentList = async () => {
    try {
      const response = await axios.get(
        " https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Registration"
      );
      setData(response.data);
    } catch (error) {
      toast.error("Error fetching Students:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchStudentList();
  }, []);


   // Format date to DD/MM/YYYY
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const handleDelete = async (index) => {
    if (index < 0 || index >= Data.length) {
      console.error("Invalid index for deletion:", index);
      return; // Early return if the index is invalid
    }
  
    const RegtoDelete = Data[index];
    if (!RegtoDelete || !RegtoDelete.Id) {
      console.error("Student to delete is not found or has no ID:", RegtoDelete);
      return; // Early return if the enquiry is undefined or ID is missing
    }
  
    const id = RegtoDelete.Id; 
    console.log("Deleting Student with ID:", id);
    
    try {
      const response = await axios.post(`https://peapi.microtechsolutions.co.in/php/delete.php?Table=Registration&Id=${id}`);
      console.log("Delete response:", response.data); // Log the response
  
      const newStudents = Data.filter((_, i) => i !== index);
      setData(newStudents);
      
      toast.success("Student Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting Student:", error);
      toast.error("Error deleting Student. Please try again.");
    }
  };
  


  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "RegistrationNo",
        header: "Registration No",
        size: 50,
      },
      {
        accessorKey: "RegistrationDate.date",
        header: "Registration Date",
        size: 50,
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for the table

      },

    
      
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => handleView(row)}
              style={{
                background: "#f0ad4e",
                color: "black",
                marginRight: "5px",
                fontSize:"22px"
              }}>
              <AiOutlineEye />
            </Button>
            <Button
    onClick={() => handleEdit(row)} // Pass the row to handleEdit
    style={{
                background: "#012144",
                color: "white",
                marginRight: "5px",
              }}>
              Edit
            </Button>
            <Button

            onClick={()=> handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",
                fontSize: "22px",
              }}>
              <RiDeleteBin5Line />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: Data,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF",
        color: "black",
        fontSize: '16px',
      },
    },
  });

  const handleView = (row) => {
    setSelectedStudent(row.original);
    setIsModalOpen(true);
  };

 

  const handleEdit = (row) => {
    const { Id } = row.original; // Assuming Id is the unique identifier for the record
    navigate('/studentUpdate/' + Id); // Navigate to the edit page with the student's ID
};

  // Close modal
  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };


  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/registration")
  }

  
  return (
    <div className="registrationlist-container">
      <h1>Student List</h1>


      <div >
          <Button
            onClick={handleBackButton}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#012144', width:'10%', display:'flex', justifyContent:"center", textAlign:'center'}}
          >
            Back
          </Button></div>
      <div className="registrationlisttable-container">

     
         
          <div className="registrationlisttable-master">
          <MaterialReactTable table={table} />

        
       </div>




      </div>

      <Modal open={isModalOpen} >
        <div className="enquirystudent-modal" style={{ padding: '20px' }}>
          {selectedStudent && (
            <>
              <h2  style={{ textAlign: "center", marginBottom: '10px', color:'#012144' }}>
                Student View
              </h2>
              <div style={{  display:'flex', justifyContent:'center',}}>
              <img
                src={selectedStudent.StudentPhoto}
                alt="Student"
                style={{ width: '100px', height: '100px', borderRadius: '5%' }}
              /></div>
            <div  >
                <p><strong>Name:</strong> {selectedStudent.StudentName} </p>
                <p><strong>Registration No:</strong> {selectedStudent.RegistrationNo} </p>
                <p><strong>Registration Date:</strong> {formatDate(selectedStudent.RegistrationDate.date)}</p>
                <p><strong>Parents Name:</strong> {selectedStudent.ParentsName} </p>
                </div>
                <div style={{  display:'flex', justifyContent:'center'}}> 
                <Button onClick={handleClose}  style={{
              
                background: "#012144",
                color: "white",
                marginTop: "10px",
              }}>
                Close
              </Button>
                </div>
             
            </>
          )}
        </div>
      </Modal>

      <ToastContainer/>

<footer className="footer">
  <small>&copy; Microtech, 2023. All rights reserved.</small>
</footer>
    </div>
  );
}

export default RegistrationList;





