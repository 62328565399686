import React from "react";
import chessboard from '../../Images/backchess.jpg'
import './Home.css'
function Home() {
  return (
    <div className="home-container">
   
        <img
          src={chessboard}
          alt="chessboard"
          className="home-image"
          style={{ width: "100%", height: "85vh" }}
        />
     
    </div>

  );
}
export default Home;
