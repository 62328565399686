import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Registration.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Box, Grid, Typography, Button } from '@mui/material';


function Registration() {
  const navigate = useNavigate("");
  const [EnquiryId, setEnquiryId] = useState("");
  const [RegistrationNo, setRegistrationNo] = useState("");
  const [RegistrationDate, setRegistrationDate] = useState("");
  const [StudentName, setStudentName] = useState("");

  const [Reference, setReference] = useState("");
  const [ParentsName, setParentsName] = useState("");
  const [ParentsJob, setParentsJob] = useState("");
  const [Address, setAddress] = useState("");
  const [CityId, setCityId] = useState('');
  const [StateId, setStateId] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SchoolName, setSchoolName] = useState("");
  const [SchoolTiming, setSchoolTiming] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [BranchId, setBranchId] = useState('');
  const [BatchId, setBatchId] = useState('');
  const [MCAId, setMCAId] = useState('');
  const [FIDEID, setFIDEId] = useState('');
  const [AICFId, setAICFId] = useState('');
  const [StudentPhoto, setStudentPhoto] = useState('');
  const [WhatsappNo, setWhatsappNo] = useState("")
 const [BirthCertificate, setBirthCertificate] = useState('')
  const [AddharCard, setAddharcard] = useState("null");



  const [errors, setErrors] = useState('')
  const handlephotochange = (e) => {
    setStudentPhoto(e.target.files[0]);
  };

  const handlebirthphotochange = (e) => {
    setBirthCertificate(e.target.files[0]);
  };
  const handleaadharphotochange = (e) => {
    setAddharcard(e.target.files[0]);
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!RegistrationNo) {
      formErrors.RegistrationNo = "Registration No is required.";
      isValid = false;
    }

    if (!RegistrationDate) {
      formErrors.RegistrationDate = "Registration Date is required.";
      isValid = false;
    }


    if (!StudentName) {
      formErrors.StudentName = "Student Name is required.";
      isValid = false;
    }

    if (!Reference) {
      formErrors.Reference = "Reference is required.";
      isValid = false;
    }

    if (!ParentsName) {
      formErrors.ParentsName = "Parents Name is required.";
      isValid = false;
    }

    if (!ParentsJob) {
      formErrors.ParentsJob = "Parents Job is required.";
      isValid = false;
    }

    
    if (!Address) {
      formErrors.Address = "Address is required.";
      isValid = false;
    }
    if (!CityId) {
      formErrors.CityId = "City is required.";
      isValid = false;
    }

    if (!StateId) {
      formErrors.StateId = "State is required.";
      isValid = false;
    }

    if (!MobileNo) {
      formErrors.MobileNo = "Mobile No is required.";
      isValid = false;
    }

    if (!SchoolName) {
      formErrors.SchoolName = "School Name is required.";
      isValid = false;
    }

    if (!SchoolTiming) {
      formErrors.SchoolTiming = "School Timing is required.";
      isValid = false;
    }

    

    

    if (!EmailId) {
      formErrors.EmailId = "Email Id is required.";
      isValid = false;
    }

    if (!BranchId) {
      formErrors.BranchId = "Branch is required.";
      isValid = false;
    }

    if (!BatchId) {
      formErrors.BatchId = "Batch is required.";
      isValid = false;
    }

    if (!MCAId) {
      formErrors.MCAId = "MCA Id is required.";
      isValid = false;
    }

    if (!FIDEID) {
      formErrors.FIDEID = "FIDE Id is required.";
      isValid = false;
    }

    if (!AICFId) {
      formErrors.AICFId = "AICF Id is required.";
      isValid = false;
    }

    if (!WhatsappNo) {
      formErrors.WhatsappNo = "Whatsapp No is required.";
      isValid = false;
    }

    



    setErrors(formErrors);
    return isValid;
  };

  const postAPI = async (e) => {
    e.preventDefault();
    if(!validateForm()) return;
    const formData = new FormData();
    formData.append("EnquiryId", 3);
    formData.append("RegistrationNo", RegistrationNo);
    formData.append("RegistrationDate", RegistrationDate);
    formData.append("StudentName", StudentName);
    formData.append("Reference", Reference);
    formData.append("ParentsName", ParentsName);
    formData.append("ParentsJob", ParentsJob);
    formData.append("Address", Address);
    formData.append("CityId", CityId);
    formData.append("StateId", StateId);
    formData.append("MobileNo", MobileNo);
    formData.append("SchoolName", SchoolName);
    formData.append("SchoolTiming", SchoolTiming);
    
    formData.append("EmailId", EmailId);
    formData.append("BranchId", BranchId);
    formData.append("BatchId", BatchId);
    formData.append("MCAId", MCAId);
    formData.append("FIDEId", FIDEID);
    formData.append("AICFId", AICFId);
    formData.append("WhatsappNo", WhatsappNo);
    formData.append("StudentPhoto", StudentPhoto);
    formData.append("BirthCertificate", BirthCertificate); // Assuming this is defined elsewhere
    formData.append("AddharCard", AddharCard);
  
    try {
      const response = await axios.post(
        "https://peapi.microtechsolutions.co.in/php/postregistration.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log(response.data);
      toast.success("Registration Submitted Successfully")
      navigate('/studentlist');
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to send data. Please try again.");
    }
  };
  

  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
const [branchOptions, setBranchOptions] = useState([]);



  

  useEffect(() => {
    fetchCity();
    fetchState();
   
    fetchBatch();
    fetchBranch();
  }, []);

  
  const fetchCity = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getcity.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.Name,
      }));
      setCityOptions(cityOptions);
      console.log(cityOptions, 'cities')
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getState.php");
      const stateOptions = response.data.map((stat) => ({
        value: stat.Id,
        label: stat.State,
      }));
      setStateOptions(stateOptions);
      console.log(stateOptions, 'states')
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };

 
  
 

  

  const fetchBranch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbranchmst.php");
      
      // Filter branches where Active is 1
      const branchOptions = response.data
        .filter(branch => branch.Active === 1)  // Filter based on Active property
        .map(branch => ({
          value: branch.Id,
          label: branch.BranchName,
        }));
      
      setBranchOptions(branchOptions);
      console.log(branchOptions, 'branches');
    } catch (error) {
      toast.error("Error fetching branches: " + error.message);
    }
  };
  

  const fetchBatch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchmst.php");
      const batchOptions = response.data.map((batch) => ({
        value: batch.Id,
        label: batch.BatchName,
      }));
      setBatchOptions(batchOptions);
      console.log(batchOptions, 'batches')
    } catch (error) {
      toast.error("Error fetching batches:", error);
    }
  };
  

  

  return (
    <>
      <div className="reg-container">
       

<form>
  
 

      <h1>Registration</h1>
        <div className="reg-form">
        

          
            <div >
              <label className="reg-label">
                Registration No:<b className="required">*</b>
              </label>
              <div>
                <input
                  type="text"
                  id="RegistrationNo"
                  name="RegistrationNo"
                  value={RegistrationNo}
                  onChange={(e) => setRegistrationNo(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Registration No"
                />
                 <div>
                    {errors.RegistrationNo && <b className="error-text">{errors.RegistrationNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Registration Date:<b className="required">*</b>
              </label>
              <div>
                <input
                  type="date"
                  id="RegistrationDate"
                  name="RegistrationDate"
                  value={RegistrationDate}
                  onChange={(e) => setRegistrationDate(e.target.value)}
                  className="reg-control"
                  placeholder="Select Date"
                />

<div>
                    {errors.RegistrationDate && <b className="error-text">{errors.RegistrationDate}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="reg-label">Student Name:<b className="required">*</b></label>
              <div>
                <input
                type="text"
                  id="StudentName"
                  name="StudentName"
                  value={StudentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Student Name"
                />
                <div>
                    {errors.StudentName && <b className="error-text">{errors.StudentName}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="reg-label">
                Student Photo:<b className="required">*</b>
              </label>
              <div>
                <input onChange={handlephotochange}
                 type="file" 
                className="reg-control"
                
                style={{padding:'7px', background:'white'}}
                />
              </div>
            </div>
            <div>
              <label className="reg-label">Reference:<b className="required">*</b></label>
              <div>
                <input
                  type="text"
                  id="Reference"
                  name="Reference" 
                  value={Reference}
                  onChange={(e) => setReference(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Reference"
                />
                <div>
                    {errors.Reference && <b className="error-text">{errors.Reference}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">Parents Name:<b className="required">*</b></label>
              <div>
                <input

                type="text"
                id="ParentsName"
                 name="ParentsName" 
                 value={ParentsName}
                  onChange={(e) => setParentsName(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Parents Name"
                />

<div>
                    {errors.ParentsName && <b className="error-text">{errors.ParentsName}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Parent's Job Profile: <b className="required">*</b>
              </label>
              <div>
                <input 
             type="text" id="ParentsJob" name="ParentsJob" value={ParentsJob}
                  onChange={(e) => setParentsJob(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Job Profile"
                />

<div>
                    {errors.ParentsJob && <b className="error-text">{errors.ParentsJob}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Address:<b className="required">*</b>
              </label>
              <div>
                <input
               type="text"
                id="Address" 
                name="Address"
                 value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Address"
                />

<div>
                    {errors.Address && <b className="error-text">{errors.Address}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="reg-label">City:<b className="required">*</b> </label>
              <div>
                <Select
                   id="CityId"
                   name="CityId"
                   value={cityOptions.find((option) => option.value === CityId)}
                   onChange={(option) => setCityId(option.value)}
                   options={cityOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="reg-label">State:<b className="required">*</b></label>
              <div>
              <Select
                   id="StateId"
                   name="StateId"
                   value={stateOptions.find((option) => option.value === StateId)}
                   onChange={(option) => setStateId(option.value)}
                   options={stateOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.StateId && <b className="error-text">{errors.StateId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Mobile No:<b className="required">*</b>
                
              </label>
              <div>
                <input
                type="text"
                id="MobileNo" 
                name="MobileNo" 
                value={MobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Mobile Number"
                />

<div>
                    {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Whatsapp No:<b className="required">*</b>
              </label>
              <div>
                <input
                 type="text"

              id="WhatsappNo" 
              name="WhatsappNo"
               value={WhatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                  className="reg-control"
                placeholder="Enter WhatsApp Number"
                />

                
<div>
                    {errors.WhatsappNo && <b className="error-text">{errors.WhatsappNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                School Name:<b className="required">*</b>
              </label>
              <div>
                <input
                 type="text"
                  id="SchoolName"
                   name="SchoolName"
                    value={SchoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  className="reg-control"
                 
                  placeholder="Enter School name"
                />

<div>
                    {errors.SchoolName && <b className="error-text">{errors.SchoolName}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                School Timing:<b className="required">*</b>
              </label>
              <div>
                <input
               type="text" 
               id="SchoolTiming"
                name="SchoolTiming" 
                value={SchoolTiming}
                   onChange={(e) => setSchoolTiming(e.target.value)}
                  className="reg-control"
                  placeholder="Enter Timing"
                />

<div>
                    {errors.SchoolTiming && <b className="error-text">{errors.SchoolTiming}</b>}
                  </div>
              </div>
            </div>

           
            <div>
              <label className="reg-label">
                Birth Certificate:<b className="required">*</b>
              </label>{" "}
              <div>
                <input onChange={handlebirthphotochange} type="file" className="reg-control" style={{padding:'7px', background:'white'}} />
              </div>
            </div>
            <div>
              <label className="reg-label">
                Aadhar Card Photo:<b className="required">*</b>
              </label>
              <div>
                <input onChange={handleaadharphotochange} type="file" className="reg-control" style={{padding:'7px', background:'white'}}/>
              </div>
            </div>
              <div >
                <label className="reg-label">
                  E-Mail Id:<b className="required">*</b>
                </label>
                <div>
                <input
                type="text" id="EmailId" name="EmailId" value={EmailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="reg-control"
                  
                  placeholder="Enter Email Address"
                />
                <div>
                    {errors.EmailId && <b className="error-text">{errors.EmailId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Batch:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="BatchId"
                   name="BatchId"
                   value={batchOptions.find((option) => option.value === BatchId)}
                   onChange={(option) => setBatchId(option.value)}
                   options={batchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.BatchId && <b className="error-text">{errors.BatchId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                Branch:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="BranchId"
                   name="BranchId"
                   value={branchOptions.find((option) => option.value === BranchId)}
                   onChange={(option) => setBranchId(option.value)}
                   options={branchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.BranchId && <b className="error-text">{errors.BranchId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label"> 
                MCA Id:<b className="required">*</b>
              </label>{" "}
              <div>
                <input
type="text" id="MCAId" name="MCAId" value={MCAId}
                  onChange={(e) => setMCAId(e.target.value)}
                  className="reg-control"
                  placeholder="Enter MCA ID"
                />
                <div>
                    {errors.MCAId && <b className="error-text">{errors.MCAId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label">
                FIDE Id:<b className="required">*</b>
              </label>{" "}
              <div>
                <input
                  type="text" id="FIDEID" name="FIDEID" value={FIDEID}
                  onChange={(e) => setFIDEId(e.target.value)}
                  className="reg-control"
                  placeholder="Enter FIDE ID"
                />
                <div>
                    {errors.FIDEID && <b className="error-text">{errors.FIDEID}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="reg-label"> 
                AICF Id:<b className="required">*</b>
              </label>
              <div>
                <input type="text"
                id="AICFId" name="AICFId" value={AICFId}
                  onChange={(e) => setAICFId(e.target.value)}
                  className="reg-control"
                  placeholder="Enter AICF ID"
                />
                <div>
                    {errors.AICFId && <b className="error-text">{errors.AICFId}</b>}
                  </div>
              </div>
            </div>

           
          
        </div></form> <div className="reg-btn-container">
             
             <Button
                     onClick={postAPI}
                     style={{
                       background: "#012144",
                       color: "white",
                     }}>
                         Submit
 
                   </Button>
              
             </div>
             
             <ToastContainer/>
             <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer> </div>
     

     
    </>
  );
}

export default Registration;