import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Branch.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";

function Branch() {
  const navigate = useNavigate
  const [BranchName, setBranchName] = useState('');
  const [Address, setAddress] = useState('');
  const [CityId, setCityId] = useState("");
  const [cityOptions, setCityoptions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [batches, setBatches] = useState([]);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');
 
  const resetForm = () => {
    setBranchName('');
    setAddress('');
    setCityId('');
    setIsModalOpen(false);
  };


  useEffect(() => {
    
    fetchEnquirytype();
    fetchEnquirymode();
    fetchBranch();
    fetchBranchmaster();
    fetchCity();
  }, [])

  const fetchCity = async () => {
   try {
     const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=City");
     const cityOptions = response.data.map((city) => ({
       value: city.Id,
       label: city.Name,
     }));
     setCityoptions(cityOptions);
     console.log(cityOptions, 'cities')
   } catch (error) {
     toast.error("Error fetching Cities:", error);
   }
 };
 
  
 

  const fetchEnquirytype = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchtyp.php");
      const enquirytypeOptions = response.data.map((enqtype) => ({
        value: enqtype.Id,
        label: enqtype.Type,
      }));
      setEnquirytypeOptions(enquirytypeOptions);
      console.log(enquirytypeOptions, 'enquiry types')
    } catch (error) {
      toast.error("Error fetching enq Types:", error);
    }
  };
  

  const fetchEnquirymode = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchmod.php");
      const enquiryModeOptions = response.data.map((mod) => ({
        value: mod.Id,
        label: mod.Mode,
      }));
      setEnquirymodeOptions(enquiryModeOptions);
      console.log(enquiryModeOptions, 'enquiry modes')
    } catch (error) {
      toast.error("Error fetching enq modes:", error);
    }
  };

  const fetchBranch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Branchmaster");
      
      // Filter branches where Active is 1
      const branchOptions = response.data.map(branch => ({
          value: branch.Id,
          label: branch.BranchName,
        }));
      
      setBranchOptions(branchOptions);
      console.log(branchOptions, 'branches');
    } catch (error) {
      toast.error("Error fetching branches: " + error.message);
    }
  };



  const fetchBranchmaster = async () => {
   try {
     const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Branchmaster");
     setBranches(response.data);
   } catch (error) {
     toast.error("Error fetching branches:", error);
   }
 };


  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true)
    setIsEditing(false); // Ensure this is set to false
  };


  const handleEdit = (row) => {

    const branch = branches[row.index];
    console.log(branch, 'branch')

   setBranchName(branch.BranchName);
   setAddress(branch.Address);
   setCityId(branch.City);
   
    setEditingIndex(row.index);
    setId(branch.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    const branchToDelete = branches[index]; 
    const id = branchToDelete.Id; 
  
    try {
      await axios.delete(`https://peapi.microtechsolutions.co.in/php/delete.php?Table=Branchmaster&Id=${id}`);
  
      const newBranches = branches.filter((_, i) => i !== index);
      setBatches(newBranches);
      
      toast.success("Branch Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting Branch:", error);
      toast.error("Error deleting Branch. Please try again.");
    }
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BranchName) {
      formErrors.BranchName = "Branch Name is required.";
      isValid = false;
    }

    if (!Address) {
      formErrors.Address = "Address is required.";
      isValid = false;
    }

    
    if (!CityId) {
      formErrors.CityId = "City is required.";
      isValid = false;
    }

   

   
    setErrors(formErrors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    const data = { 
     BranchName : BranchName,
     Address : Address,
     City : CityId
     };
  
    // Check if editing and set the appropriate URL
    const url = isEditing
      ? "https://peapi.microtechsolutions.co.in/php/setbranchmst.php"
      : "https://peapi.microtechsolutions.co.in/php/postbranchmst.php";
  
    // Only add Id when editing
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Branch updated successfully!');
      } else {
        toast.success('Branch added successfully!');
      }
     
      fetchBranchmaster(); // Refresh the list after submit
      resetForm();
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
 
   
 
   
 const [branchOptions, setBranchOptions] = useState([]);
 const [enquiryModeOptions, setEnquirymodeOptions] = useState([]);
 const [enquirytypeOptions, setEnquirytypeOptions] = useState([]);
 
 
   
 
  


 

   const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "BranchName",
      header: "Branch Name",
      size: 50,
    },
   
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#012144",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [branches]);

  const table = useMaterialReactTable({ columns, data: branches,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });
   
 
   
 

  return (



    <div className="branch-container">
    <h1>Branch</h1>
    <div className="branchtable-master">

   
      <div className="branchtable1-master">
      <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",display:'flex',
             fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}
        >
          New
        </Button>
        <div className="branchtable-container">
       
          <MaterialReactTable table={table} />
        </div>
      </div>

      {isModalOpen && <div className="branch-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="branch-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}
          >
            {editingIndex >= 0 ? "Edit Branch " : "Add Branch "}
          </h1>

          <form>


          <div className="branch-form">
        
          <div>
              <label className="branch-label">
               Branch Name:<b className="required">*</b>
              </label>
              <div>
              <input
type="text"
 id="BranchName" 
 name="BranchName" 
 value={BranchName}
                  onChange={(e) => setBranchName(e.target.value)}
                  className="branch-control"
                  placeholder="Enter Branch Name"
                />

<div>
                    {errors.BranchName && <b className="error-text">{errors.BranchName}</b>}
                  </div>
              </div>
            </div>


            <div>
              <label className="branch-label">
               Address:<b className="required">*</b>
              </label>
              <div>
              <input
type="text" id="Address" name="Address" value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="branch-control"
                  placeholder="Enter Address"
                />

<div>
                    {errors.Address && <b className="error-text">{errors.Address}</b>}
                  </div>
              </div>
            </div>

          
           

            <div>
              <label className="branch-label">
                 City:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="CityId"
                   name="CityId"
                   value={cityOptions.find((option) => option.value === CityId)}
                   onChange={(option) => setCityId(option.value)}
                   options={cityOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                  </div>
              </div>
            </div>


           

         
           
           
          
        </div></form>
          <div className="branch-btn-container">
            <Button
              type="submit"
              style={{
                background: "#012144",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {editingIndex >= 0 ? "Update" : "Save"}
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                background: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    <ToastContainer />

    <footer className="footer">
      <small>&copy; Microtech, 2023. All rights reserved.</small>
    </footer>
  </div>


  );
}

export default Branch;
