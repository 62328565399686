




import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Coach.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";

function Coach() {
  // State variables
 const [CoachName, setCoachName] = useState('');
 const [MobileNo, setMobileNo] = useState('');

 const [Coaches, setCoaches] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');

  // Fetch book groups data
  useEffect(() => {
    fetchCoach();
  }, []);

  const fetchCoach = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Coachmaster");
      setCoaches(response.data);
    } catch (error) {
      toast.error("Error fetching coaches:", error);
    }
  };

  const resetForm = () => {
    setCoachName("");
    setMobileNo("");
    setIsModalOpen(false);
  };

  

  const handleEdit = (row) => {
    const coach = Coaches[row.index];
    console.log(coach, 'coach')
    setCoachName(coach.CoachName);
    setMobileNo(coach.MobileNo);
    setEditingIndex(row.index);
    setId(coach.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    const newCoaches = Coaches.filter((_, i) => i !== index);
    setCoaches(newCoaches);
    toast.error("Coach Deleted Successfully!");
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!CoachName) {
      formErrors.CoachName = "Coach Name is required.";
      isValid = false;
    }

    if (!MobileNo) {
      formErrors.MobileNo = "Mobile No is required.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true)
    setIsEditing(false); // Ensure this is set to false
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    const data = { CoachName, MobileNo };
  
    // Check if editing and set the appropriate URL
    const url = isEditing
      ? "https://peapi.microtechsolutions.co.in/php/setcoachmst.php"
      : "https://peapi.microtechsolutions.co.in/php/postcoachmst.php";
  
    // Only add Id when editing
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Coach updated successfully!');
      } else {
        toast.success('Coach added successfully!');
      }
      resetForm();
      fetchCoach(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

  const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "CoachName",
      header: "Coach Name",
      size: 50,
    },
    {
      accessorKey: "MobileNo",
      header: "Mobile Number",
      size: 50,
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#012144",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [Coaches]);

  const table = useMaterialReactTable({ columns, data: Coaches,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });

  return (
    <div className="coach-container">
      <h1>Coach</h1>
      <div className="coachtable-master">

     
        <div className="coachtable1-master">
        <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",display:'flex',
               fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}
          >
            New
          </Button>
          <div className="coachtable-container">
         
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="coach-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="coach-modal">
            <h1
              style={{
                textAlign: "center",
                fontWeight: "500",
                margin: "2px",
              }}
            >
              {editingIndex >= 0 ? "Edit Coach " : "Add Coach "}
            </h1>
            <form className="coach-form">
              <div>
                <label className="coach-label">
                  Coach Name:<b className="required">*</b>
                </label>
                <div>
                  <input
                    type="text"
                    id="CoachName"
                    name="CoachName"
                    value={CoachName}
                    onChange={(e) => setCoachName(e.target.value)}
                    className="coach-control"
                    placeholder="Enter Coach Name"
                  />
                  <div>
                    {errors.CoachName && <b className="error-text">{errors.CoachName}</b>}
                  </div>
                </div>
              </div>

              <div>
                <label className="coach-label">
                 Mobile Number:<b className="required">*</b>
                </label>
                <div>
                  <input
                    type="number"
                    id="MobileNo"
                    name="MobileNo"
                    value={MobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    className="coach-control"
                    placeholder="Enter Mobile Number"
                  />
                  <div>
                    {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                  </div>
                </div>
              </div>
            </form>
            <div className="coach-btn-container">
              <Button
                type="submit"
                style={{
                  background: "#012144",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                {editingIndex >= 0 ? "Update" : "Save"}
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />

      <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default Coach;
