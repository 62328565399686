import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Attendance.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {  Button } from '@mui/material';


function Attendance() {
  const navigate = useNavigate("");

  const [AttendanceDate, setAttendanceDate] = useState('');
  const [BranchId, setBranchId] = useState("");
  const [BatchId, setBatchId] = useState("");
  const [StudentId, setStudentId] = useState();
  const [Present, setPresent] = useState(false);
  const [branchOptions, setBranchOptions] = useState([]);
 const [batchOptions, setBatchOptions] = useState([]);
 const [studentOptions, setStudentOptions] = useState([]);
 
 


 const resetForm = () => {
  setAttendanceDate('');
  setStudentId(''); // Reset to null
  setBatchId(''); // Reset to null
  setBranchId(''); // Reset to null
  setPresent(false);
};
 

const postAPI = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append("AttenDate", AttendanceDate);
  formData.append("BranchId", BranchId);
  formData.append("BatchId", BatchId);
  formData.append("StudentId", StudentId);
  formData.append("Present", Present);

  try {
    const response = await axios.post(
      "https://peapi.microtechsolutions.co.in/php/postatten.php",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(response.data);
    toast.success('Attendance Submitted Successfully');
    
    // Reset the form only after a successful submission
    resetForm();
  } catch (error) {
    console.error("Error uploading image:", error);
    toast.error("Failed to send data. Please try again.");
  }
};

  



  

  useEffect(() => {
   
    
    fetchBatch();
    fetchBranch();
    fetchStudent();
  }, [])


 
  
 

  const fetchBatch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchmaster");
      const batchOptions = response.data.map((batch) => ({
        value: batch.Id,
        label: batch.BatchName,
      }));
    setBatchOptions(batchOptions);
      console.log(batchOptions, 'batches')
    } catch (error) {
      toast.error("Error fetching batches:", error);
    }
  };

  const fetchBranch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Branchmaster");
      const branchOptions = response.data.map((branch) => ({
        value: branch.Id,
        label: branch.BranchName,
      }));
    setBranchOptions(branchOptions);
      console.log(branchOptions, 'branches')
    } catch (error) {
      toast.error("Error fetching branches:", error);
    }
  };

  const fetchStudent = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Registration");
      const studentOptions = response.data.map((stu) => ({
        value: stu.Id,
        label: stu.StudentName,
      }));
    setStudentOptions(studentOptions);
      console.log(studentOptions, 'students')
    } catch (error) {
      toast.error("Error fetching students:", error);
    }
  };
  

  

  

  return (
    <>
      <div className="attendance-container">
       

<form>
  
 

<h1 >Attendance</h1>
        <div className="attendance-form">
        

          
           
        <div>
              <label className="attendance-label">
                Attendance Date:<b className="required">*</b>
              </label>
              <div>
              <input
type="date" id="AttendanceDate" name="AttendanceDate" value={AttendanceDate}
                  onChange={(e) => setAttendanceDate(e.target.value)}
                  className="attendance-control"
                  style={{width:'170px'}}
                  placeholder="Enter Date"
                />
              </div>
            </div>


           
            <div>
              <label className="attendance-label">
                Branch :<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="BranchId"
                   name="BranchId"
                   value={branchOptions.find((option) => option.value === BranchId) || null}
                   onChange={(option) =>setBranchId(option.value)}
                   options={branchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
              </div>
            </div>
            <div>
              <label className="attendance-label">
                Batch:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="BatchId"
                   name="BatchId"
                   value={batchOptions.find((option) => option.value === BatchId) || null}
                   onChange={(option) => setBatchId(option.value)}
                   options={batchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
              </div>
            </div>


            <div>
              <label className="attendance-label">
                Student:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="StudentId"
                   name="StudentId"
                   value={studentOptions.find((option) => option.value === StudentId) || null}
                   onChange={(option) => setStudentId(option.value)}
                   options={studentOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
              </div>
            </div>


            <div>
              <label className="attendance-label">
                Present:<b className="required">*</b>
              </label>{" "}
              <div>
              <input
                type="checkbox" id="Present" name="Present" checked={Present}
                  onChange={(e) => setPresent(e.target.checked)}
                  className="attendance-control"
                  placeholder="Select Present or"
                />
              </div>
            </div>

            
           
           
           
          
        </div></form> <div className="attendance-btn-container">
             
             <Button
                     onClick={postAPI}
                     style={{
                       background: "#012144",
                       color: "white",
                     }}>
                         Submit
 
                   </Button>
              
             </div> 
             <ToastContainer/>
             <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer></div>
     

    
    </>
  );
}

export default Attendance;