import React from 'react';
import { Modal, Button } from "@mui/material";
import './Enquirylist.css'

function EnquiryModal({ selectedEnquiry, isModalOpen, handleClose }) {

       // Format date to DD/MM/YYYY
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <div className="enquirystudent-modal">
        {selectedEnquiry && (
          <div>
            <h2 style={{ textAlign: "center", marginBottom: '10px', color:'#012144' }}>
              Student View
            </h2>
            <div style={{ display:'flex', justifyContent:'center' }}>
              <img
                src={selectedEnquiry.StudentPhoto}
                alt="Student"
                style={{ width: '100px', height: '100px', borderRadius: '5%' }}
              />
            </div>
            <div>
              <p><strong>Name:</strong> {selectedEnquiry.StudentName}</p>
              <p><strong>Enquiry No:</strong> {selectedEnquiry.EnquiryNo}</p>
              <p><strong>Enquiry Date:</strong> {formatDate(selectedEnquiry.EnquiryDate.date)}</p>
              <p><strong>Parents Name:</strong> {selectedEnquiry.ParentsName}</p>
            </div>
            <div style={{ display:'flex', justifyContent:'center' }}>
              <Button 
                onClick={handleClose}  
                style={{
                  background: "#012144",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default EnquiryModal;
