import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './Master.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function Master() {


  const navigate = useNavigate('')
  const handleBatchMode = () => {
    navigate('/batchmodelist');
  }


  const handleBatchType = () => {
    navigate('/batchtypelist');
  }

  const handleCity = () => {
    navigate('/citylist');
  }

  const handleState = () => {
    navigate('/statelist');
  }

  const handleSubject = () => {
    navigate('/subjectlist');
  }



  return (
    <div className="master-container">
      <h1>Masters</h1>

      <div className='mastercard-container'>
      <Card className="master-card" onClick={handleBatchType} style={{cursor:'pointer'}}>
        <CardContent>Batch Type Master</CardContent>
      </Card>

      <Card className="master-card" onClick={handleBatchMode} style={{cursor:'pointer'}}>
        <CardContent>Batch Mode Master</CardContent>
      </Card> 

      <Card className="master-card" onClick={handleCity} style={{cursor:'pointer'}}>
        <CardContent>City Master</CardContent>
      </Card>

      <Card className="master-card" onClick={handleState} style={{cursor:'pointer'}}>
        <CardContent>State Master</CardContent>
      </Card>

      <Card className="master-card" onClick={handleSubject} style={{cursor:'pointer'}}>
        <CardContent>Subject Master</CardContent>
      </Card>
      </div>
     <ToastContainer/>
     <footer className="footer" style={{marginTop:'50px'}}>
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default Master;
