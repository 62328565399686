import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./List.css";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function BatchmodeList() {
  const [data, setData] = useState([]);

  // Fetch batch list
  const fetchBatchmodelist = async () => {
    try {
      const response = await axios.get(
        "https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchmode"
      );
      console.log(response.data.length, "response of batchmodelist");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching batchmodelist:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchBatchmodelist();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "Mode",
        header: "Mode",
        size: 50,
      },

    
     
    ],
    []
  );


  const navigate = useNavigate('')
  const handleBackbutton = () => {
    navigate('/master')
  }

  return (
    <div className="batchmodelist-container">
      <h1>Batchmode List </h1>

      <div className="batchmodelisttable-container">

      <Button onClick={handleBackbutton}   style={{ color: "#FFFF", 
              fontWeight: "700", background:'#012144', width:'10%', display:'flex', justifyContent:"center", textAlign:'center'}}
          >Back</Button>
        <div className="batchmodetable">
          <MaterialReactTable columns={columns} data={data} />{" "}
        </div>
      </div>
      <ToastContainer/>
      <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default BatchmodeList;
