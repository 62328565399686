import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { MaterialReactTable,useMaterialReactTable } from "material-react-table";
import { Modal, Button, Typography } from "@mui/material";
import { RiDeleteBin5Line, RiH2 } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import "./Enquirylist.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EnquiryModal from './EnquiryModal'; // Adjust the path as necessary

function Enquirylist() {
  const [Data, setData] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch enquiry list
  const fetchEnquirylist = async () => {
    try {
      const response = await axios.get(
        "https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Enquiry"
      );
      setData(response.data);
    } catch (error) {
      toast.error("Error fetching Enquiries:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchEnquirylist();
  }, []);


   // Format date to DD/MM/YYYY
   const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (index) => {
    if (index < 0 || index >= Data.length) {
      console.error("Invalid index for deletion:", index);
      return; // Early return if the index is invalid
    }
  
    const enqToDelete = Data[index];
    if (!enqToDelete || !enqToDelete.Id) {
      console.error("Enquiry to delete is not found or has no ID:", enqToDelete);
      return; // Early return if the enquiry is undefined or ID is missing
    }
  
    const id = enqToDelete.Id; 
    console.log("Deleting Enquiry with ID:", id);
    
    try {
      const response = await axios.post(`https://peapi.microtechsolutions.co.in/php/delete.php?Table=Enquiry&Id=${id}`);
      console.log("Delete response:", response.data); // Log the response
  
      const newEnqs = Data.filter((_, i) => i !== index);
      setData(newEnqs);
      
      toast.success("Enquiry Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting Enquiry:", error);
      toast.error("Error deleting Enquiry. Please try again.");
    }
  };
  


  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "EnquiryNo",
        header: "Enquiry No",
        size: 50,
      },
      {
        accessorKey: "EnquiryDate.date",
        header: "Enquiry Date",
        size: 50,
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for the table

      },

      // {
      //   accessorKey: "StudentName",
      //   header: "Student Name",
      //   size: 50,
      // },
      // {
      //   accessorKey: "ParentsName",
      //   header: "Parent Name",
      //   size: 50,
      // },
     
      
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => handleView(row)}
              style={{
                background: "#f0ad4e",
                color: "black",
                marginRight: "5px",
                fontSize:"22px"
              }}>
              <AiOutlineEye />
            </Button>
            <Button
    onClick={() => handleEdit(row)} // Pass the row to handleEdit
    style={{
                background: "#012144",
                color: "white",
                marginRight: "5px",
              }}>
              Edit
            </Button>
            <Button

            onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",
                fontSize: "22px",
              }}>
              <RiDeleteBin5Line />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: Data,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF",
        color: "black",
        fontSize: '16px',
      },
    },
  });

  const handleView = (row) => {
    setSelectedEnquiry(row.original);
    setIsModalOpen(true);
  };

 

  const handleEdit = (row) => {
    const { Id } = row.original; // Assuming Id is the unique identifier for the record
    navigate('/enquiryUpdate/' + Id); // Navigate to the edit page with the student's ID
};

  // Close modal
  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedEnquiry(null);
  };


  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/enquiry")
  }

  
  return (
    <div className="enquirylist-container">
      
      {/* Include EnquiryModal here */}
    <EnquiryModal 
        selectedEnquiry={selectedEnquiry} 
        isModalOpen={isModalOpen} 
        handleClose={handleClose} 
      />
      
      <Button
        onClick={handleBackButton}
        style={{ 
          color: "#FFFF", 
          fontWeight: "700", 
          background:'#012144', 
          width:'10%', 
          display:'flex', 
          justifyContent:"center", 
          textAlign:'center'
        }}
      >
        Back
      </Button>
      <h1>Enquiry List</h1>
    
  
      <div className="enquirylisttable-container">
        <div className="enquirylisttable-master">
          <MaterialReactTable table={table} />
        </div>
      </div>
      
      <ToastContainer/>
  
    
      <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default Enquirylist;





