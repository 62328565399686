import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./List.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function BatchtypeList() {
  const [data, setData] = useState([]);

  // Fetch batch list
  const fetchBatchtypelist = async () => {
    try {
      const response = await axios.get(
        "https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchtype"
      );
      console.log(response.data.length, "response of batchtypelist");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching batchtypelist:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchBatchtypelist();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "Type",
        header: "Type",
        size: 50,
      },

    
      
    ],
    []
  );
const navigate = useNavigate('')
  const handleBackbutton = () => {
    navigate('/master')
  }

  return (
    <div className="batchtypelist-container">
      <h1>BatchType List</h1>

      <div className="batchtypelisttable-container">

        <Button onClick={handleBackbutton}   style={{ color: "#FFFF", 
              fontWeight: "700", background:'#012144', width:'10%', display:'flex', justifyContent:"center", textAlign:'center'}}
          >Back</Button>
        <div className="batchtypetable">
          <MaterialReactTable columns={columns} data={data} />{" "}
        </div>
      </div>
      <ToastContainer/>
      <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default BatchtypeList;
