
import { MdDashboard, MdHome } from "react-icons/md";
import { MdQuestionAnswer , MdPersonAdd , MdCheckCircle, MdPayment, MdGroup , 
  MdBusiness, MdList,  MdNote ,  
  
     } from "react-icons/md"; // Import the enquiry icon
import {FaClipboardList, FaFont, Facoach } from 'react-icons/fa';
export const menuItems = [
  // {
  //   title: 'Anayas Chess Club', icon: <FaChess/>,
  // },
  {
    title: "Home",
    icon: <MdHome />,
    path: "/home",
  },
  {
    title: "Enquiry",
    icon: <MdQuestionAnswer />,
    path: "/enquiry",
  },
  {
    title: "Registration",
    icon: <MdPersonAdd />,
    path: "/registration",
  },

  {
    title: "Attendance",
    icon: <MdCheckCircle  />,
    path: "/attendance",
  },
  {
    title: "Fees",
    icon: <MdPayment />,
    path: "/fees",
  },
  {
    title: "Master",
    icon: <MdGroup  />,
    path: "/master",
  },

  {
    title: "Branch",
    icon: <MdBusiness  />,
    path: "/branch",
  },
  {
    title: "Batch",
    icon: <MdList  />,
    path: "/batch",
  },
  {
    title: "Coach",
    icon: <MdList  />,
    path: "/coach",
  },

  {
    title: "Subject",
    icon: <MdNote />,
    path: "/subject",
  },
  {
    title: "BatchList",
    icon: <FaClipboardList  />,
    path: "/batchlist",
  },
  {
    title: "Type",
    icon: <FaFont />,
    path: "/type",
  },
  {
    title: "Enquiry List",
    icon: <FaClipboardList />,
    path: "/enquirylist",
  },
  //   {
  //     title: "Masters",
  //     path: "/masters",
  //     icon: <FaGraduationCap />,

  //     submenus: [
  //       {
  //         title: "Accounts",
  //         path: "/masters/accounts",
  //         icon: <FaUsers />,
  //       },
  //     ],
  //   },
];
