import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Box, Grid, Typography, Button } from '@mui/material';
import './Registration.css'

function RegUpdate(){

  const { Id } = useParams(); // Get the ID from the URL



  const navigate = useNavigate("");

  const [RegistrationId, setRegistrationId] = useState("");

  const [RegistrationNo, setRegistrationNo] = useState("");
  const [RegistrationDate, setRegistrationDate] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [Reference, setReference] = useState("");
  const [ParentsName, setParentsName] = useState("");
  const [ParentsJob, setParentsJob] = useState("");
  const [Address, setAddress] = useState("");
  const [CityId, setCityId] = useState('');
  const [StateId, setStateId] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SchoolName, setSchoolName] = useState("");
  const [SchoolTiming, setSchoolTiming] = useState("");

  const [EmailId, setEmailId] = useState("");
  const [BranchId, setBranchId] = useState('');
  const [BatchId, setBatchId] = useState('');
  const [MCAId, setMCAId] = useState('');
  const [FIDEId, setFIDEId] = useState('');
  const [AICFId, setAICFId] = useState('');
  const [StudentPhoto, setStudentPhoto] = useState('');
  const [WhatsappNo, setWhatsappNo] = useState("")
 const [BirthCertificate, setBirthCertificate] = useState('')
  const [AddharCard, setAddharcard] = useState("null");
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
const [branchOptions, setBranchOptions] = useState([]);



  

  useEffect(() => {
    fetchCity();
    fetchState();
    
    fetchBatch();
    fetchBranch();
  }, [])

  const fetchCity = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getcity.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.Name,
      }));
      setCityOptions(cityOptions);
      console.log(cityOptions, 'cities')
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getState.php");
      const stateOptions = response.data.map((stat) => ({
        value: stat.Id,
        label: stat.State,
      }));
      setStateOptions(stateOptions);
      console.log(stateOptions, 'states')
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };

 
  
 

 

  const fetchBranch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbranchmst.php");
      const branchOptions = response.data.map((branch) => ({
        value: branch.Id,
        label: branch.BranchName,
      }));
      setBranchOptions(branchOptions);
      console.log(branchOptions, 'branches')
    } catch (error) {
      toast.error("Error fetching branches:", error);
    }
  };

  const fetchBatch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchmst.php");
      const batchOptions = response.data.map((batch) => ({
        value: batch.Id,
        label: batch.BatchName,
      }));
      setBatchOptions(batchOptions);
      console.log(batchOptions, 'batches')
    } catch (error) {
      toast.error("Error fetching batches:", error);
    }
  };

    
 

  const handlephotochange = (e) => {
    setStudentPhoto(e.target.files[0]);
  }


  const handlebirthphotochange = (e) => {
    setBirthCertificate(e.target.files[0]);
  }
  const handleaadharphotochange = (e) => {
    setAddharcard(e.target.files[0]);
  }





  const postAPI = () => {

    const formData = new FormData();
    formData.append('Id',RegistrationId);
    formData.append('RegistrationNo', RegistrationNo);
    formData.append('RegistrationDate', RegistrationDate);
    formData.append('StudentName', StudentName);
    formData.append('Reference', Reference);
    formData.append('ParentsName', ParentsName);
    formData.append('ParentsJob', ParentsJob);
    formData.append('Address', Address);
    formData.append('CityId', CityId);
    formData.append('StateId', StateId);
    formData.append('MobileNo', MobileNo);
    formData.append('SchoolName', SchoolName);
    formData.append('SchoolTiming', SchoolTiming);
   
    formData.append('EmailId', EmailId);
    formData.append('BranchId',BranchId );
    formData.append('BatchId', BatchId);
    formData.append('MCAId', MCAId);
    formData.append('FIDEId', FIDEId);
    formData.append('AICFId', AICFId);
    formData.append('WhatsappNo', WhatsappNo);
    formData.append('StudentPhoto', StudentPhoto);
    formData.append('BirthCertificate', BirthCertificate);
    formData.append('AddharCard', AddharCard);

    try {
      const response = axios.post('https://peapi.microtechsolutions.co.in/php/setregistration.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      toast.success("Student Updated Successfully !!!")

      // Wait for 2 seconds before navigating
          setTimeout(() => {
             navigate('/studentlist');
          }, 2000); // Delay of 2000 milliseconds (2 seconds)
 
    } catch (error) {
      toast.error('Error uploading student:', error);
    }

  };





  useEffect(() => {
    const getcategory = async () => {
      try {
        const response = await fetch(
          `https://peapi.microtechsolutions.co.in/php/getbyid.php?Table=Registration&Colname=Id&Colvalue=${Id}`
        );
      
      const data = await response.json();
      RegistrationData(data);
    } catch (error) {
      console.error("Error fetching Category:", error);
    }
    }
  
    getcategory();
  
  }, []);
  

  
const RegistrationData=(getdata)=>{


  console.log(getdata, 'data from student')
  
  {
    getdata.map((item)=>(
      setRegistrationId(item.Id),
      setRegistrationNo(item.RegistrationNo),
      setRegistrationDate((item.RegistrationDate.date).substring(0, 10)),
    setStudentName (item.StudentName),
    setReference(item.Reference),
    setParentsName(item.ParentsName),
    setParentsJob(item.ParentsJob),
    setAddress(item.Address),
    setMobileNo(item.MobileNo),
    setSchoolName(item.SchoolName),
    setSchoolTiming(item.SchoolTiming),
   
    setEmailId(item.EmailId),
    setMCAId(item.MCAId),
    setFIDEId(item.FIDEId),
    setAICFId(item.AICFId),
    setWhatsappNo(item.WhatsappNo),
    setCityId(item.CityId),
    setStateId(item.StateId),
    setBatchId(item.BatchId),
    setBranchId(item.BranchId),
    setStudentPhoto(item.StudentPhoto), // Set the URL directly
    setBirthCertificate(item.BirthCertificate), // Set the URL directly
    setAddharcard(item.AddharCard)// Set the URL directly
    
  
  
  
    ))
  }
  
  }


  const handlestudentlist = ()=>{
    navigate('/studentlist');
  }



  


  return (
    <>
    <div className="reg-container">
     

<form>

<Button style={{ color: "#FFFF",display:'flex',
               fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}onClick={handlestudentlist} >Student List</Button>

   <h1>Student Update</h1>
      <div className="reg-form">

        
          <div >
            <label className="regupdate-label">
              Registration No:<b className="required">*</b>
            </label>
            <div>
            

                                      <input  
                                      id='RegistrationNo' name='RegistrationNo' 
                                      value={RegistrationNo}
                                      onChange={(e)=>setRegistrationNo(e.target.value)}
                                      className="regupdate-control"
                                      type="text"
                                      placeholder="Enter Registration No"
                                      // disabled
                                      />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Registration Date:<b className="required">*</b>
            </label>
            <div>
              <input
                type="date"
                id="RegistrationDate"
                name="RegistrationDate"
                value={RegistrationDate}
                onChange={(e) => setRegistrationDate(e.target.value)}
                className="regupdate-control"
                placeholder="Select Date"
              />
            </div>
          </div>

          <div>
            <label className="regupdate-label">Student Name:<b className="required">*</b></label>
            <div>
              <input
              type="text"
                id="StudentName"
                name="StudentName"
                value={StudentName}
                onChange={(e) => setStudentName(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Student Name"
              />
            </div>
          </div>

         
          <div>
            <label className="regupdate-label">Reference:<b className="required">*</b></label>
            <div>
              <input
                type="text"
                id="Reference"
                name="Reference" 
                value={Reference}
                onChange={(e) => setReference(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Reference"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">Parents Name:<b className="required">*</b></label>
            <div>
              <input

              type="text"
              id="ParentsName"
               name="ParentsName" 
               value={ParentsName}
                onChange={(e) => setParentsName(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Parents Name"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Parent's Job Profile: <b className="required">*</b>
            </label>
            <div>
              <input 
           type="text" id="ParentsJob" name="ParentsJob" value={ParentsJob}
                onChange={(e) => setParentsJob(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Job Profile"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Address:<b className="required">*</b>
            </label>
            <div>
              <input
             type="text"
id="Address" name="Address" value={Address}
                onChange={(e) => setAddress(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Address"
              />
            </div>
          </div>

          <div>
            <label className="regupdate-label">City:<b className="required">*</b> </label>
            <div>
              <Select
                 id="CityId"
                 name="CityId"
                 value={cityOptions.find((option) => option.value === CityId)}
                 onChange={(option) => setCityId(option.value)}
                 options={cityOptions}
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                     marginTop: "10px",
                     borderRadius: "4px",
                     border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                   }),
                 }}
                 placeholder="Select Id"
                 />
            </div>
          </div>

          <div>
            <label className="regupdate-label">State:<b className="required">*</b></label>
            <div>
            <Select
                 id="StateId"
                 name="StateId"
                 value={stateOptions.find((option) => option.value === StateId)}
                 onChange={(option) => setStateId(option.value)}
                 options={stateOptions}
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                     marginTop: "10px",
                     borderRadius: "4px",
                     border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                   }),
                 }}
                 placeholder="Select Id"
                 />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Mobile No:<b className="required">*</b>
              
            </label>
            <div>
              <input
              type="text"
              id="MobileNo" name="MobileNo" value={MobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Whatsapp No:<b className="required">*</b>
            </label>
            <div>
              <input
               type="text"
            id="WhatsappNo" name="WhatsappNo" value={WhatsappNo}
                onChange={(e) => setWhatsappNo(e.target.value)}
                className="regupdate-control"
              placeholder="Enter WhatsApp Number"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              School Name:<b className="required">*</b>
            </label>
            <div>
              <input
               type="text" id="SchoolName" name="SchoolName" value={SchoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                className="regupdate-control"
               
                placeholder="Enter School name"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              School Timing:<b className="required">*</b>
            </label>
            <div>
              <input
             type="text" 
             id="SchoolTiming"
              name="SchoolTiming" 
              value={SchoolTiming}
                 onChange={(e) => setSchoolTiming(e.target.value)}
                className="regupdate-control"
                placeholder="Enter Timing"
              />
            </div>
          </div>

          
          
            <div >
              <label className="regupdate-label">
                E-Mail Id:<b className="required">*</b>
              </label>
              <div>
              <input
              type="text" id="EmailId" name="EmailId" value={EmailId}
                onChange={(e) => setEmailId(e.target.value)}
                className="regupdate-control"
                
                placeholder="Enter Email Address"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Batch:<b className="required">*</b>
            </label>{" "}
            <div>
            <Select
                 id="BatchId"
                 name="BatchId"
                 value={batchOptions.find(option => option.value === BatchId)} // Set the selected option based on state
                 onChange={(option) => setBatchId(option.value)}
                 options={batchOptions}
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                     marginTop: "10px",
                     borderRadius: "4px",
                     border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                   }),
                 }}
                 placeholder="Select Id"
                 />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              Branch:<b className="required">*</b>
            </label>
            <div>
            <Select
                 id="BranchId"
                 name="BranchId"
                 value={branchOptions.find((option) => option.value === BranchId)}
                 onChange={(option) => setBranchId(option.value)}
                 options={branchOptions}
                 styles={{
                   control: (base) => ({
                     ...base,
                     width: "170px",
                     marginTop: "10px",
                     borderRadius: "4px",
                     border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                   }),
                 }}
                 placeholder="Select Id"
                 />
            </div>
          </div>
          <div>
            <label className="regupdate-label"> 
              MCA Id:<b className="required">*</b>
            </label>{" "}
            <div>
              <input
type="text" id="MCAId" name="MCAId" value={MCAId}
                onChange={(e) => setMCAId(e.target.value)}
                className="regupdate-control"
                placeholder="Enter MCA ID"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label">
              FIDE Id:<b className="required">*</b>
            </label>{" "}
            <div>
              <input
                type="text" id="FIDEId" name="FIDEId" value={FIDEId}
                onChange={(e) => setFIDEId(e.target.value)}
                className="regupdate-control"
                placeholder="Enter FIDE ID"
              />
            </div>
          </div>
          <div>
            <label className="regupdate-label"> 
              AICF Id:<b className="required">*</b>
            </label>
            <div>
              <input type="text"
              id="AICFId" name="AICFId" value={AICFId}
                onChange={(e) => setAICFId(e.target.value)}
                className="regupdate-control"
  
                placeholder="Enter AICF ID"
              />
            </div>
          </div>
          <div>
  <label className="regupdate-label">
    Student Photo:<b className="required">*</b>
  </label>
  <div>
    <img
      src={StudentPhoto}
      alt='Student Photo'
      style={{ width: '100px', height: '100px', cursor: 'pointer' }}
      onClick={() => document.getElementById('studentPhotoInput').click()} // Trigger file input on click
    />
    <input
      id="studentPhotoInput"
      type="file"
      style={{ display: 'none' }} // Hide the input
      onChange={handlephotochange}
    />
  </div>
</div>

<div>
  <label className="regupdate-label">
    Birth Certificate:<b className="required">*</b>
  </label>
  <div>
    <img
      src={BirthCertificate}
      alt='Birth Certificate'
      style={{ width: '100px', height: '100px', cursor: 'pointer' }}
      onClick={() => document.getElementById('birthCertificateInput').click()} // Trigger file input on click
    />
    <input
      id="birthCertificateInput"
      type="file"
      style={{ display: 'none' }} // Hide the input
      onChange={handlebirthphotochange}
    />
  </div>
</div>

<div>
  <label className="regupdate-label">
    Aadhar Card Photo:<b className="required">*</b>
  </label>
  <div>
    <img
      src={AddharCard}
      alt='Aadhar Card'
      style={{ width: '100px', height: '100px', cursor: 'pointer' }}
      onClick={() => document.getElementById('aadharCardInput').click()} // Trigger file input on click
    />
    <input
      id="aadharCardInput"
      type="file"
      style={{ display: 'none' }} // Hide the input
      onChange={handleaadharphotochange}
    />
  </div>
</div>


         
        
      </div></form> 
      <div className="regupdate-btn-container">
           
           <Button
                   onClick={postAPI}
                   style={{
                     background: "#012144",
                     color: "white",
                   }}>
                       Update

                 </Button>
            
           </div> 
           <ToastContainer/>
           
           <footer className="footer">
      <small>&copy; Microtech, 2023. All rights reserved.</small>
    </footer></div>
   

   
  </>
  )
}

export default RegUpdate;
