// // import React, { useState } from "react";
// // import "./App.css";
// // import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// // import { HashRouter as Router, Routes, Route } from "react-router-dom";
// // import { ProSidebarProvider } from "react-pro-sidebar";
// // import { Navigate } from "react-router-dom";
// // import Sidebar from "./Components/Sidebar";
// // import Login from "./Components/Login";
// // import Home from "./Pages/Home";
// // import Enquiry from "./Pages/Enquiry/Enquiry";
// // import Registration from "./Pages/Registration/Registration";
// // import Attendance from "./Pages/Attendance/Attendance";
// // import Fees from "./Pages/Fees/Fees";
// // import Master from "./Pages/Master/Master";
// // import Branch from "./Pages/Branch/Branch";
// // import Batch from "./Pages/Batch/Batch";
// // import Coach from "./Pages/Coach/Coach";
// // import Subject from "./Pages/Subject/Subject";
// // import Type from "./Pages/Type/Type";
// // import Enquirylist from "./Pages/Enquiry/Enquirylist";
// // import BatchList from './Pages/Batch/Batchlist'
// // import EnquiryUpdate from "./Pages/Enquiry/EnquiryUpdate";
// // import RegistrationList from "./Pages/Registration/RegistrationList";
// // import RegUpdate from "./Pages/Registration/RegUpdate";
// // import BatchmodeList from "./Pages/Master/BatchmodeList";
// // import BatchtypeList from "./Pages/Master/BatchtypeList";
// // import Citylist from "./Pages/Master/Citylist";
// // import Statelist from "./Pages/Master/Statelist";
// // import Subjectlist from './Pages/Master/Subjectlist';

// // function App() {
// //   const [isAuthenticated, setIsAuthenticated] = useState(false); // New state for authentication



// //   const exitApp = () => {
// //     console.log("Exiting app...");
// //   };

// //   const handleLogin = () => {
// //     // Implement your login logic here
// //     setIsAuthenticated(true); // Set authenticated state to true
// //   };

// //   return (
// //     <div className="App">
// //       <ProSidebarProvider>
// //         <Router>
// //           <Routes>
// //           <Route path="/login" element={<Login />} />    
// //           {isAuthenticated ? (
// //                   <Route path="/sidebar" element={<Sidebar />}>
// //               <Route path="/home" element={<Home />} />
// //               <Route path="/enquiry" element={<Enquiry />} />
// //               <Route path="/enquiryUpdate/:Id" element={<EnquiryUpdate />} />
// //               <Route path="/batchmodelist" element={<BatchmodeList/>}/>
// //               <Route path="/batchtypelist" element={<BatchtypeList/>}/>
// //               <Route path="/citylist" element={<Citylist/>}/>
// //               <Route path="/statelist" element={<Statelist/>}/>
// //               <Route path="/subjectlist" element={<Subjectlist/>}/>
// //               <Route path="/registration" element={<Registration />} />
// //               <Route path="/studentUpdate/:Id" element={<RegUpdate/>}/>
// //               <Route path="/attendance" element={<Attendance />} />
// //               <Route path="/fees" element={<Fees />} />
// //               <Route path="/master" element={<Master />} />
// //               <Route path="/branch" element={<Branch />} />
// //               <Route path="/batch" element={<Batch />} />
// //               <Route path="/coach" element={<Coach />} />
// //               <Route path="/subject" element={<Subject />} />
// //               <Route path="/type" element={<Type />} />
// //               <Route path="/enquirylist" element={<Enquirylist />} />
// //               <Route path="/studentlist" element={<RegistrationList />} />
// //               <Route path="/batchlist" element={<BatchList />} />
// //             </Route>
// //             ) : (
// //               <Route path="/" element={<Navigate to="/login" />} />
// //             )}
// //           </Routes>
// //         </Router>
// //       </ProSidebarProvider>
// //     </div>
// //   );
// // }

// // export default App;




// import React, { useState } from "react";
// import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import Sidebar from "./Components/Sidebar";
// import Login from "./Components/Login";
// import Home from "./Pages/Home";
// import Enquiry from "./Pages/Enquiry/Enquiry";
// import Registration from "./Pages/Registration/Registration";
// import Attendance from "./Pages/Attendance/Attendance";
// import Fees from "./Pages/Fees/Fees";
// import Master from "./Pages/Master/Master";
// import Branch from "./Pages/Branch/Branch";
// import Batch from "./Pages/Batch/Batch";
// import Coach from "./Pages/Coach/Coach";
// import Subject from "./Pages/Subject/Subject";
// import Type from "./Pages/Type/Type";
// import Enquirylist from "./Pages/Enquiry/Enquirylist";
// import BatchList from './Pages/Batch/Batchlist';
// import EnquiryUpdate from "./Pages/Enquiry/EnquiryUpdate";
// import RegistrationList from "./Pages/Registration/RegistrationList";
// import RegUpdate from "./Pages/Registration/RegUpdate";
// import BatchmodeList from "./Pages/Master/BatchmodeList";
// import BatchtypeList from "./Pages/Master/BatchtypeList";
// import Citylist from "./Pages/Master/Citylist";
// import Statelist from "./Pages/Master/Statelist";
// import Subjectlist from './Pages/Master/Subjectlist';

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication

//   return (
//     <div className="App">
//       <ProSidebarProvider>
//         <Router>
//           <Routes>
//             <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
//             {isAuthenticated ? (
//               <Route path="/" element={<Sidebar />}>
//                 <Route path="/home" element={<Home />} />
//                 <Route path="/enquiry" element={<Enquiry />} />
//                 <Route path="/enquiryUpdate/:Id" element={<EnquiryUpdate />} />
//                 <Route path="/batchmodelist" element={<BatchmodeList />} />
//                 <Route path="/batchtypelist" element={<BatchtypeList />} />
//                 <Route path="/citylist" element={<Citylist />} />
//                 <Route path="/statelist" element={<Statelist />} />
//                 <Route path="/subjectlist" element={<Subjectlist />} />
//                 <Route path="/registration" element={<Registration />} />
//                 <Route path="/studentUpdate/:Id" element={<RegUpdate />} />
//                 <Route path="/attendance" element={<Attendance />} />
//                 <Route path="/fees" element={<Fees />} />
//                 <Route path="/master" element={<Master />} />
//                 <Route path="/branch" element={<Branch />} />
//                 <Route path="/batch" element={<Batch />} />
//                 <Route path="/coach" element={<Coach />} />
//                 <Route path="/subject" element={<Subject />} />
//                 <Route path="/type" element={<Type />} />
//                 <Route path="/enquirylist" element={<Enquirylist />} />
//                 <Route path="/studentlist" element={<RegistrationList />} />
//                 <Route path="/batchlist" element={<BatchList />} />
//               </Route>
//             ) : (
//               <Route path="/" element={<Navigate to="/login" />} />
//             )}
//           </Routes>
//         </Router>
//       </ProSidebarProvider>
//     </div>
//   );
// };
// export default App;






import React, { useState, useEffect } from "react";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import Sidebar from "./Components/Sidebar";
import Login from "./Components/Login";
import Home from "./Pages/Home/Home";
import Enquiry from "./Pages/Enquiry/Enquiry";
import Registration from "./Pages/Registration/Registration";
import Attendance from "./Pages/Attendance/Attendance";
import Fees from "./Pages/Fees/Fees";
import Master from "./Pages/Master/Master";
import Branch from "./Pages/Branch/Branch";
import Batch from "./Pages/Batch/Batch";
import Coach from "./Pages/Coach/Coach";
import Subject from "./Pages/Subject/Subject";
import Type from "./Pages/Type/Type";
import Enquirylist from "./Pages/Enquiry/Enquirylist";
import BatchList from './Pages/Batch/Batchlist';
import EnquiryUpdate from "./Pages/Enquiry/EnquiryUpdate";
import RegistrationList from "./Pages/Registration/RegistrationList";
import RegUpdate from "./Pages/Registration/RegUpdate";
import BatchmodeList from "./Pages/Master/BatchmodeList";
import BatchtypeList from "./Pages/Master/BatchtypeList";
import Citylist from "./Pages/Master/Citylist";
import Statelist from "./Pages/Master/Statelist";
import Subjectlist from './Pages/Master/Subjectlist';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  return (
    <div className="App">
      <ProSidebarProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            {isAuthenticated ? (
              <Route path="/" element={<Sidebar />}>
                <Route path="/home" element={<Home />} />
                <Route path="/enquiry" element={<Enquiry />} />
                <Route path="/enquiryUpdate/:Id" element={<EnquiryUpdate />} />
                <Route path="/batchmodelist" element={<BatchmodeList />} />
                <Route path="/batchtypelist" element={<BatchtypeList />} />
                <Route path="/citylist" element={<Citylist />} />
                <Route path="/statelist" element={<Statelist />} />
                <Route path="/subjectlist" element={<Subjectlist />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/studentUpdate/:Id" element={<RegUpdate />} />
                <Route path="/attendance" element={<Attendance />} />
                <Route path="/fees" element={<Fees />} />
                <Route path="/master" element={<Master />} />
                <Route path="/branch" element={<Branch />} />
                <Route path="/batch" element={<Batch />} />
                <Route path="/coach" element={<Coach />} />
                <Route path="/subject" element={<Subject />} />
                <Route path="/type" element={<Type />} />
                <Route path="/enquirylist" element={<Enquirylist />} />
                <Route path="/studentlist" element={<RegistrationList />} />
                <Route path="/batchlist" element={<BatchList />} />
              </Route>
            ) : (
              <Route path="/" element={<Navigate to="/login" />} />
            )}


          </Routes>
        </Router>
      </ProSidebarProvider>
    </div>
  );
}

export default App;
