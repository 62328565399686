import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Enquiry.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Box, Grid, Typography, Button } from '@mui/material';





function Enquiry() {
  const navigate = useNavigate("");
  const [EnquiryNo, setenquiryNo] = useState("");
  const [EnquiryDate, setEnquiryDate] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [Reference, setReference] = useState("");
  const [ParentsName, setParentsName] = useState("");
  const [ParentsJob, setParentsJob] = useState("");
  const [Address, setAddress] = useState("");
  const [CityId, setCityId] = useState('');
  const [StateId, setStateId] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SchoolName, setSchoolName] = useState("");
  const [SchoolTiming, setSchoolTiming] = useState("");
  const [EnquiryModeId, setEnquiryModeId] = useState('');
  const [EnquiryTypeId, setEnquiryTypeId]= useState('');
  const [EmailId, setEmailId] = useState("");
  const [PreferredTimingId, setPreferredTimingId] = useState('');
  const [PreferredBatchId, setPreferredBatchId] = useState('');
  const [MCAId, setMCAId] = useState('');
  const [FIDEID, setFIDEId] = useState('');
  const [AICFId, setAICFId] = useState('');
  const [StudentPhoto, setStudentPhoto] = useState('');
  const [WhatsappNo, setWhatsappNo] = useState("")
  const [BirthCertificate, setBirthCertificate] = useState('')
  const [AddharCard, setAddharcard] = useState("null");
  const [errors, setErrors] = useState('');


  const handlephotochange = (e) => {
    setStudentPhoto(e.target.files[0]);
  };

  const handlebirthphotochange = (e) => {
    setBirthCertificate(e.target.files[0]);
  };
  const handleaadharphotochange = (e) => {
    setAddharcard(e.target.files[0]);
  };

  

  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [enquiryModeOptions, setEnquirymodeOptions] = useState([]);
  const [enquirytypeOptions, setEnquirytypeOptions] = useState([]);


  

  useEffect(() => {
    fetchCity();
    fetchState();
    fetchEnquirytype();
    fetchEnquirymode();
    fetchBatch();
    fetchBranch();
  }, []);

  const fetchCity = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getcity.php");
      const cityOptions = response.data.map((city) => ({
        value: city.Id,
        label: city.Name,
      }));
      setCityOptions(cityOptions);
      console.log(cityOptions, 'cities')
    } catch (error) {
      toast.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getState.php");
      const stateOptions = response.data.map((stat) => ({
        value: stat.Id,
        label: stat.State,
      }));
      setStateOptions(stateOptions);
      console.log(stateOptions, 'states')
    } catch (error) {
      toast.error("Error fetching states:", error);
    }
  };

 
  
 

  const fetchEnquirytype = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchtyp.php");
      const enquirytypeOptions = response.data.map((enqtype) => ({
        value: enqtype.Id,
        label: enqtype.Type,
      }));
      setEnquirytypeOptions(enquirytypeOptions);
      console.log(enquirytypeOptions, 'enquiry types')
    } catch (error) {
      toast.error("Error fetching enq Types:", error);
    }
  };
  

  const fetchEnquirymode = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchmod.php");
      const enquiryModeOptions = response.data.map((mod) => ({
        value: mod.Id,
        label: mod.Mode,
      }));
      setEnquirymodeOptions(enquiryModeOptions);
      console.log(enquiryModeOptions, 'enquiry modes')
    } catch (error) {
      toast.error("Error fetching enq modes:", error);
    }
  };

  const fetchBranch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Branchmaster");
      
   
      const branchOptions = response.data.map(branch => ({
          value: branch.Id,
          label: branch.BranchName,
        }));
      
      setBranchOptions(branchOptions);
      console.log(branchOptions, 'branches');
    } catch (error) {
      toast.error("Error fetching branches: " + error.message);
    }
  };
  

  const fetchBatch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchmaster");
      const batchOptions = response.data.map((batch) => ({
        value: batch.Id,
        label: batch.BatchName,
      }));
      setBatchOptions(batchOptions);
      console.log(batchOptions, 'batches')
    } catch (error) {
      toast.error("Error fetching batches:", error);
    }
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!EnquiryNo) {
      formErrors.EnquiryNo = "Enquiry No is required.";
      isValid = false;
    }

    if (!EnquiryDate) {
      formErrors.EnquiryDate = "Enquiry Date is required.";
      isValid = false;
    }


    if (!StudentName) {
      formErrors.StudentName = "Student Name is required.";
      isValid = false;
    }

    if (!Reference) {
      formErrors.Reference = "Reference is required.";
      isValid = false;
    }

    if (!ParentsName) {
      formErrors.ParentsName = "Parents Name is required.";
      isValid = false;
    }

    if (!ParentsJob) {
      formErrors.ParentsJob = "Parents Job is required.";
      isValid = false;
    }

    
    if (!Address) {
      formErrors.Address = "Address is required.";
      isValid = false;
    }
    if (!CityId) {
      formErrors.CityId = "City is required.";
      isValid = false;
    }

    if (!StateId) {
      formErrors.StateId = "State is required.";
      isValid = false;
    }

    if (!MobileNo) {
      formErrors.MobileNo = "Mobile No is required.";
      isValid = false;
    }

    if (!SchoolName) {
      formErrors.SchoolName = "School Name is required.";
      isValid = false;
    }

    if (!SchoolTiming) {
      formErrors.SchoolTiming = "School Timing is required.";
      isValid = false;
    }

    if (!EnquiryModeId) {
      formErrors.EnquiryModeId = "Enquiry Mode is required.";
      isValid = false;
    }

    if (!EnquiryTypeId) {
      formErrors.EnquiryTypeId = "Enquiry Type is required.";
      isValid = false;
    }

    if (!EmailId) {
      formErrors.EmailId = "Email Id is required.";
      isValid = false;
    }

    if (!PreferredTimingId) {
      formErrors.PreferredTimingId = "Branch is required.";
      isValid = false;
    }

    if (!PreferredBatchId) {
      formErrors.PreferredBatchId = "Batch is required.";
      isValid = false;
    }

    if (!MCAId) {
      formErrors.MCAId = "MCA Id is required.";
      isValid = false;
    }

    if (!FIDEID) {
      formErrors.FIDEID = "FIDE Id is required.";
      isValid = false;
    }

    if (!AICFId) {
      formErrors.AICFId = "AICF Id is required.";
      isValid = false;
    }

    if (!WhatsappNo) {
      formErrors.WhatsappNo = "Whatsapp No is required.";
      isValid = false;
    }
    setErrors(formErrors);
    return isValid;
  };


  const postAPI = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("EnquiryNo", EnquiryNo);
    formData.append("EnquiryDate", EnquiryDate);
    formData.append("StudentName", StudentName);
    formData.append("Reference", Reference);
    formData.append("ParentsName", ParentsName);
    formData.append("ParentsJob", ParentsJob);
    formData.append("Address", Address);
    formData.append("CityId", CityId);
    formData.append("StateId", StateId);
    formData.append("MobileNo", MobileNo);
    formData.append("SchoolName", SchoolName);
    formData.append("SchoolTiming", SchoolTiming);
    formData.append("EnquiryModeId", EnquiryModeId);
    formData.append("EnquiryTypeId", EnquiryTypeId);
    formData.append("EmailId", EmailId);
    formData.append("PreferredTimingId", PreferredTimingId);
    formData.append("PreferredBatchId", PreferredBatchId);
    formData.append("MCAId", MCAId);
    formData.append("FIDEId", FIDEID);
    formData.append("AICFId", AICFId);
    formData.append("WhatsappNo", WhatsappNo);
    formData.append("StudentPhoto", StudentPhoto);
    formData.append("BirthCertificate", BirthCertificate);
    formData.append("AddharCard", AddharCard);
  
    try {
      const response = await axios.post(
        "https://peapi.microtechsolutions.co.in/php/postenquiry.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log(response.data);
      navigate("/enquirylist");
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to send data. Please try again.");
    }
  };
  
  

  

  return (
    <>
      <div className="enquiry-container">
<form>
<h1>Enquiry</h1>
        <div className="enquiry-form">
            <div>
              <label className="enquiry-label">
                Enquiry No:<b className="required">*</b>
              </label>
              <div>
                <input
                  type="text"
                  id="EnquiryNo"
                  name="EnquiryNo"
                  value={EnquiryNo}
                  onChange={(e) => setenquiryNo(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Enquiry No"
                />
                  <div>
                    {errors.EnquiryNo && <b className="error-text">{errors.EnquiryNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Enquiry Date:<b className="required">*</b>
              </label>
              <div>
                <input
                  type="date"
                  id="EnquiryDate"
                  name="EnquiryDate"
                  value={EnquiryDate}
                  onChange={(e) => setEnquiryDate(e.target.value)}
                  className="enquiry-control"
                  placeholder="Select Date"
                />

<div>
                    {errors.EnquiryDate && <b className="error-text">{errors.EnquiryDate}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">Student Name:<b className="required">*</b></label>
              <div>
                <input
                type="text"
                  id="StudentName"
                  name="StudentName"
                  value={StudentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Student Name"
                />

<div>
                    {errors.StudentName && <b className="error-text">{errors.StudentName}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">
                Student Photo:
              </label>
              <div>
                <input onChange={handlephotochange}
                 type="file" 
                className="enquiry-control"
                
                style={{padding:'7px', background:'white'}}
                />


              </div>
            </div>
            <div>
              <label className="enquiry-label">Reference:<b className="required">*</b></label>
              <div>
                <input
                  type="text"
                  id="Reference"
                  name="Reference" 
                  value={Reference}
                  onChange={(e) => setReference(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Reference"
                />

<div>
                    {errors.Reference && <b className="error-text">{errors.Reference}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">Parents Name:<b className="required">*</b></label>
              <div>
                <input

                type="text"
                id="ParentsName"
                 name="ParentsName" 
                 value={ParentsName}
                  onChange={(e) => setParentsName(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Parents Name"
                />

              <div>
                    {errors.ParentsName && <b className="error-text">{errors.ParentsName}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Parent's Job Profile: <b className="required">*</b>
              </label>
              <div>
                <input 
             type="text" id="ParentsJob" name="ParentsJob" value={ParentsJob}
                  onChange={(e) => setParentsJob(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Job Profile"
                />

<div>
                    {errors.ParentsJob && <b className="error-text">{errors.ParentsJob}</b>}
                  </div>
              </div>
            </div>



            <div>
              <label className="enquiry-label">
                Address:<b className="required">*</b>
              </label>
              <div>
                <input
               type="text"
                id="Address" 
                name="Address"
                 value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Address"
                />
                <div>
                    {errors.Address && <b className="error-text">{errors.Address}</b>}
                  </div>
              </div>
            </div>


            <div>
              <label className="enquiry-label">City:<b className="required">*</b></label>
              <div>
                <Select
                   id="CityId"
                   name="CityId"
                   value={cityOptions.find((option) => option.value === CityId)}
                   onChange={(option) => setCityId(option.value)}
                   options={cityOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.CityId && <b className="error-text">{errors.CityId}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">State:<b className="required">*</b></label>
              <div>
              <Select
                   id="StateId"
                   name="StateId"
                   value={stateOptions.find((option) => option.value === StateId)}
                   onChange={(option) => setStateId(option.value)}
                   options={stateOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.StateId && <b className="error-text">{errors.StateId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Mobile No:<b className="required">*</b>
                
              </label>
              <div>
                <input
                type="text"
                id="MobileNo" 
                name="MobileNo" 
                value={MobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Mobile Number"
                />

<div>
                    {errors.MobileNo && <b className="error-text">{errors.MobileNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Whatsapp No:<b className="required">*</b>
              </label>
              <div>
                <input
                 type="text"

              id="WhatsappNo" 
              name="WhatsappNo"
               value={WhatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                  className="enquiry-control"
                placeholder="Enter WhatsApp Number"
                />

                    <div>
                    {errors.WhatsappNo && <b className="error-text">{errors.WhatsappNo}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                School Name:<b className="required">*</b>
              </label>
              <div>
                <input
                 type="text"
                  id="SchoolName"
                   name="SchoolName"
                    value={SchoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  className="enquiry-control"
                 
                  placeholder="Enter School name"
                />

<div>
                    {errors.SchoolName && <b className="error-text">{errors.SchoolName}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                School Timing:<b className="required">*</b>
              </label>
              <div>
                <input
               type="text" 
               id="SchoolTiming"
                name="SchoolTiming" 
                value={SchoolTiming}
                   onChange={(e) => setSchoolTiming(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter Timing"
                />

                <div>
                    {errors.SchoolTiming && <b className="error-text">{errors.SchoolTiming}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">
                Enquiry Mode:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="EnquiryModeId"
                   name="EnquiryModeId"
                   value={enquiryModeOptions.find((option) => option.value === EnquiryModeId)}
                   onChange={(option) => setEnquiryModeId(option.value)}
                   options={enquiryModeOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.EnquiryModeId && <b className="error-text">{errors.EnquiryModeId}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">
                Enquiry Type:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="EnquiryTypeId"
                   name="EnquiryTypeId"
                   value={enquirytypeOptions.find((option) => option.value === EnquiryTypeId)}
                   onChange={(option) => setEnquiryTypeId(option.value)}
                   options={enquirytypeOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
                   <div>
                    {errors.EnquiryTypeId && <b className="error-text">{errors.EnquiryTypeId}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="enquiry-label">
                Birth Certificate:
              </label>{" "}
              <div>
                <input onChange={handlebirthphotochange} type="file" className="enquiry-control" style={{padding:'7px', background:'white'}} />
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Aadhar Card Photo:
              </label>
              <div>
                <input onChange={handleaadharphotochange} type="file" className="enquiry-control" style={{padding:'7px',background:'white'}}/>
              </div>
            </div>
              <div >
                <label className="enquiry-label">
                  E-Mail Id:<b className="required">*</b>
                </label>
                <div>
                <input
                type="text" id="EmailId" name="EmailId" value={EmailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="enquiry-control"
                  
                  placeholder="Enter Email Address"
                />

<div>
                    {errors.EmailId && <b className="error-text">{errors.EmailId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Batch:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="PreferredBatchId"
                   name="PreferredBatchId"
                   value={batchOptions.find((option) => option.value === PreferredBatchId)}
                   onChange={(option) => setPreferredBatchId(option.value)}
                   options={batchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.PreferredBatchId && <b className="error-text">{errors.PreferredBatchId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                Branch:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="PrefferedTimingId"
                   name="PrefferedTimingId"
                   value={branchOptions.find((option) => option.value === PreferredTimingId)}
                   onChange={(option) => setPreferredTimingId(option.value)}
                   options={branchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.PreferredTimingId && <b className="error-text">{errors.PreferredTimingId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label"> 
                MCA Id:<b className="required">*</b>
              </label>{" "}
              <div>
                <input
                type="text"
                 id="MCAId" 
                 name="MCAId"
                  value={MCAId}
                  onChange={(e) => setMCAId(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter MCA ID"
                />

                

<div>
                    {errors.MCAId && <b className="error-text">{errors.MCAId}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label">
                FIDE Id:<b className="required">*</b>
              </label>{" "}
              <div>
                <input
                  type="text" id="FIDEID" name="FIDEID" value={FIDEID}
                  onChange={(e) => setFIDEId(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter FIDE ID"
                />
                <div>
                    {errors.FIDEID && <b className="error-text">{errors.FIDEID}</b>}
                  </div>
              </div>
            </div>
            <div>
              <label className="enquiry-label"> 
                AICF Id:<b className="required">*</b>
              </label>
              <div>
                <input type="text"
                id="AICFId" name="AICFId" value={AICFId}
                  onChange={(e) => setAICFId(e.target.value)}
                  className="enquiry-control"
                  placeholder="Enter AICF ID"
                />

<div>
                    {errors.AICFId && <b className="error-text">{errors.AICFId}</b>}
                  </div>
              </div>
            </div>

           
          
        </div></form> <div className="enquiry-btn-container">
             
             <Button
                     onClick={postAPI}
                     style={{
                       background: "#012144",
                       color: "white",
                     }}>
                         Submit
 
                   </Button>
              
             </div>
             <ToastContainer/>   <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer></div>
     

    
    </>
  );
}

export default Enquiry;