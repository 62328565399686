import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./List.css";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Citylist() {
  const [data, setData] = useState([]);

  // Fetch batch list
  const fetchcity = async () => {
    try {
      const response = await axios.get(
        "https://peapi.microtechsolutions.co.in/php/gettable.php?Table=City"
      );
      console.log(response.data.length, "response of citylist");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching citylist:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchcity();
  }, []);

  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "Name",
        header: "City",
        size: 50,
      },

    
    //   {
    //     accessorKey: "actions",
    //     header: "Actions",
    //     size: 150,
    //     Cell: ({ row }) => (
    //       <div>
    //         <Button
    //           // onClick={() => handleEdit(row)}
    //           style={{
    //             background: "#012144",
    //             color: "white",
    //             marginRight: "5px",
    //           }}>
    //           Edit
    //         </Button>
    //         <Button
    //           // onClick={() => handleDelete(row.index)}
    //           style={{
    //             background: "red",
    //             color: "white",
    //             fontSize: "22px",
    //           }}>
    //           <RiDeleteBin5Line />
    //         </Button>
    //       </div>
    //     ),
    //   },
    ],
    []
  );

  const table = useMaterialReactTable({ columns, 
    data: data,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",
        fontSize: '16px'
      },
    },
   });



  const navigate = useNavigate('')
  const handleBackbutton = () => {
    navigate('/master')
  }

  return (
    <>
    <div className="citylist-container">
      <h1>City List </h1>

      <div className="citylisttable-container">

      <Button onClick={handleBackbutton}   style={{ color: "#FFFF", 
              fontWeight: "700", background:'#012144', width:'10%', display:'flex', justifyContent:"center", textAlign:'center'}}
          >Back</Button>
        <div className="citytable">
          <MaterialReactTable table={table} />{" "}
        </div>
      </div>
      <ToastContainer/>

      <footer className="footer">
<small>&copy; Microtech, 2023. All rights reserved.</small>
</footer>
    
    </div>


</>
  );
}

export default Citylist;
