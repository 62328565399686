import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import background from '../Images/backchess.jpg';
import logo from '../Images/logochess.jpg';
import './Login.css';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const bgStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto',
  };

  const handleLogin = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('Name', username);
      formData.append('Password', password);

      const response = await fetch('https://peapi.microtechsolutions.co.in/php/postlogin.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (response.ok) {
        toast.success("Welcome ANAYA'S CHESS CLUB");
        setIsAuthenticated(true);
        navigate('/home'); 
      } else {
        setError('Invalid username or password');
        toast.error("Incorrect Username or Password");
      }
    } catch (error) {
      toast.error('Error during login:', error);
      setError('An unexpected error occurred');
    }
  };

  return (
    <div style={bgStyle} className='login-page'>
      <div className='login-container'>
        <div className='login-master'>
          <img alt="panelImage" src={logo} className='login-image' />
          {error && <p style={{ color: 'red', textSize: '25px' }}>{error}</p>}
          <form className='login-form'>
            <div>
              <label className='login-label'>Username:</label>
              <div>
              <input
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className='login-control'
                placeholder='Enter the Username'
              />
              </div>
            
            </div>
            <div>
              <label className='login-label'>Password:</label>
              <div>
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='login-control'
                placeholder='Enter the Password'
              />
              </div>
              
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button onClick={handleLogin} style={{
                       background: "#012144",
                       color: "white", borderRadius:'5px', height:'30px', width:'50px', cursor:'pointer'
                     }} >
                Login
              </Button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Login;
