




import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Fees.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";

function Fees() {
  // State variables
 const [StudentId, setStudentId] = useState('');
  const [FeesDate, setFeesDate] = useState('');
  const [Month, setMonth] = useState("");
  const [Year, setYear] = useState('');
  const [Fee, setFee] = useState('');
const [studentOptions, setStudentOptions] = useState([]);

 const [fees, setfees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');

  // Fetch book groups data
  useEffect(() => {
    fetchFees();
    fetchStudent()
  }, []);

  const fetchFees = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Fees");
      setfees(response.data);
    } catch (error) {
      toast.error("Error fetching fees:", error);
    }
  };


  const fetchStudent = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Registration");
      const studentOptions = response.data.map((stu) => ({
        value: stu.Id,
        label: stu.StudentName,
      }));
    setStudentOptions(studentOptions);
      console.log(studentOptions, 'students')
    } catch (error) {
      toast.error("Error fetching students:", error);
    }
  };

  const resetForm = () => {
    setStudentId("");
    setFeesDate('');
    setMonth('');
    setYear('');
    setFee('');
    setIsModalOpen(false);
  };


  // Convert date strings to YYYY-MM-DD format for the input fields
  const convertDateForInput = (dateStr) => {
    if (typeof dateStr === 'string' && dateStr.includes('-')) {
        const [year, month, day] = dateStr.split(' ')[0].split('-');
        return `${year}-${month}-${day}`;
    } else {
        console.error('Invalid date format:', dateStr);
        return ''; // Return an empty string or handle it as needed
    }
};
  

  const handleEdit = (row) => {
    const fee = fees[row.index];
    console.log(fee, 'fees');
    setStudentId(fee.StudentId);
    
    setFeesDate(convertDateForInput(fee.FeesDate.date));

    setMonth(fee.Month);
    setYear(fee.Year);
    setFee(fee.Fees);
    setEditingIndex(row.index);
    setId(fee.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    const newFees = fees.filter((_, i) => i !== index);
    setfees(newFees);
    toast.error("Fee Deleted Successfully!");
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!StudentId) {
      formErrors.StudentId = "Student is required.";
      isValid = false;
    }

    if (!FeesDate) {
      formErrors.FeesDate = "Fees Date is required.";
      isValid = false;
    }

    
    if (!Month) {
      formErrors.Month = "Month is required.";
      isValid = false;
    }

    if (!Year) {
      formErrors.Year = "Year is required.";
      isValid = false;
    }

    if (!Fee) {
      formErrors.Fees = "Fees is required.";
      isValid = false;
    }

   
    setErrors(formErrors);
    return isValid;
  };

  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true)
    setIsEditing(false); // Ensure this is set to false
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    const data = {
      StudentId: StudentId,
      FeesDate : FeesDate,
      Month : Month,
      Year : Year,
      Fees: Fee
    }
  
    // Check if editing and set the appropriate URL
    const url = isEditing
      ? "https://peapi.microtechsolutions.co.in/php/setfees.php"
      : "https://peapi.microtechsolutions.co.in/php/postfees.php";
  
    // Only add Id when editing
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Fees updated successfully!');
      } else {
        toast.success('Fees added successfully!');
      }
      resetForm();
      fetchFees(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

  const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "StudentId",
      header: "Student ID",
      size: 50,
    },

    {
      accessorKey: "FeesDate.date",
      header: "Fees Date",
      size: 50,
    },
   
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#012144",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [fees]);

  const table = useMaterialReactTable({ columns, 
    data: fees,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",
        fontSize: '16px'
      },
    },
   });

  return (


    <div className="fees-container">
    <h1>Fees</h1>
    <div className="feestable-master">

   
      <div className="feestable1-master">
      <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",display:'flex',
             fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}
        >
          New
        </Button>
        <div className="feestable-container">
       
          <MaterialReactTable table={table} />
        </div>
      </div>

      {isModalOpen && <div className="fees-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="fees-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}
          >
            {editingIndex >= 0 ? "Edit Fees " : "Add Fees "}
          </h1>

          <form>


          <div className="fees-form">
              <div>
                <label className="fees-label">
                  Student <b className="required">*</b>
                </label>
                <div>
              <Select
                   id="StudentId"
                   name="StudentId"
                   value={studentOptions.find((option) => option.value === StudentId)}
                  //  value={
                  //   isEditing
                  //     ? studentOptions.find(option => option.value === StudentId) 
                  //     : null 
                  // }
                   onChange={(option) => setStudentId(option.value)}
                   options={studentOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />
              </div>
              <div>
                    {errors.StudentId && <b className="error-text">{errors.StudentId}</b>}
                  </div>
                </div>


                <div>
                <label className="fees-label">
                  Fees Date <b className="required">*</b>
                </label>
                <div>
                <input
                    type="date"
                    id="FeesDate"
                    name="FeesDate"
                    value={FeesDate}
                    onChange={(e) => setFeesDate(e.target.value)}
                    className="fees-control"
                    placeholder="Enter FeesDate"
                  />
              </div>
              <div>
                    {errors.FeesDate && <b className="error-text">{errors.FeesDate}</b>}
                  </div>
                </div>

                <div>
                <label className="fees-label">
                 Month <b className="required">*</b>
                </label>
                <div>
                <input
                    type="text"
                    id="Month"
                    name="Month"
                    value={Month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="fees-control"
                    placeholder="Enter Month"
                  />
              </div>
              <div>
                    {errors.Month && <b className="error-text">{errors.Month}</b>}
                  </div>
                </div>

                <div>
                <label className="fees-label">
                 Year <b className="required">*</b>
                </label>
                <div>
                <input
                    type="text"
                    id="Year"
                    name="Year"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                    className="fees-control"
                    placeholder="Enter Year"
                  />
              </div>
              <div>
                    {errors.Year && <b className="error-text">{errors.Year}</b>}
                  </div>
                </div>

                <div>
                <label className="fees-label">
                  Fees <b className="required">*</b>
                </label>
                <div>
                <input
                    type="text"
                    id="Fee"
                    name="Fee"
                    value={Fee}
                    onChange={(e) => setFee(e.target.value)}
                    className="fees-control"
                    placeholder="Enter Fee"
                  />
              </div>
              <div>
                    {errors.Fee && <b className="error-text">{errors.Fee}</b>}
                  </div>
                </div>

            </div>
            </form>
          <div className="fees-btn-container">
            <Button
              type="submit"
              style={{
                background: "#012144",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {editingIndex >= 0 ? "Update" : "Save"}
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                background: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    <ToastContainer />

    <footer className="footer">
      <small>&copy; Microtech, 2023. All rights reserved.</small>
    </footer>
  </div>







    
  );
}

export default Fees;
