import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Batch.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";

function Batch() {
  const navigate = useNavigate
  const [Fees, setFees] = useState('');
  const [Timing, setTiming] = useState('');
  const [BatchName, setBatchName] = useState('');
  const [EnquiryModeId, setEnquiryModeId] = useState('');
  const [BranchId, setBranchId] = useState('');
  const [EnquiryTypeId, setEnquiryTypeId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [batches, setBatches] = useState([]);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState('');
 
  const resetForm = () => {
    setFees(""); setTiming('');
    setBatchName(''); setEnquiryModeId(''); setBranchId(''); setEnquiryTypeId(''); 
    setIsModalOpen(false);
  };


  const handleNewClick = () => {
    resetForm();
    setEditingIndex(-1);
    setIsModalOpen(true)
    setIsEditing(false); // Ensure this is set to false
  };


 
   
 
   
 const [branchOptions, setBranchOptions] = useState([]);
 const [enquiryModeOptions, setEnquirymodeOptions] = useState([]);
 const [enquirytypeOptions, setEnquirytypeOptions] = useState([]);
 
 
   
 
   useEffect(() => {
    
     fetchEnquirytype();
     fetchEnquirymode();
     fetchBranch();
     fetchBatch();
   }, [])
 
 
  
   
  
 
   const fetchEnquirytype = async () => {
     try {
       const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchtyp.php");
       const enquirytypeOptions = response.data.map((enqtype) => ({
         value: enqtype.Id,
         label: enqtype.Type,
       }));
       setEnquirytypeOptions(enquirytypeOptions);
       console.log(enquirytypeOptions, 'enquiry types')
     } catch (error) {
       toast.error("Error fetching enq Types:", error);
     }
   };
   
 
   const fetchEnquirymode = async () => {
     try {
       const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbatchmod.php");
       const enquiryModeOptions = response.data.map((mod) => ({
         value: mod.Id,
         label: mod.Mode,
       }));
       setEnquirymodeOptions(enquiryModeOptions);
       console.log(enquiryModeOptions, 'enquiry modes')
     } catch (error) {
       toast.error("Error fetching enq modes:", error);
     }
   };
 
   const fetchBranch = async () => {
     try {
       const response = await axios.get("https://peapi.microtechsolutions.co.in/php/getbranchmst.php");
       
       // Filter branches where Active is 1
       const branchOptions = response.data
         .filter(branch => branch.Active === 1)  // Filter based on Active property
         .map(branch => ({
           value: branch.Id,
           label: branch.BranchName,
         }));
       
       setBranchOptions(branchOptions);
       console.log(branchOptions, 'branches');
     } catch (error) {
       toast.error("Error fetching branches: " + error.message);
     }
   };



   const fetchBatch = async () => {
    try {
      const response = await axios.get("https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchmaster");
      setBatches(response.data);
    } catch (error) {
      toast.error("Error fetching batches:", error);
    }
  };


  const handleEdit = (row) => {
    const batch = batches[row.index];
    console.log(batch, 'batch')
    setEnquiryModeId(batch.ModeId);
    setBranchId(batch.BranchId);
    setBatchName(batch.BatchName);
    setEnquiryTypeId(batch.TypeId);
    setTiming(batch.Timing);
    setFees(batch.Fees);

    setEditingIndex(row.index);
    setId(batch.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (index) => {
    const batchToDelete = batches[index]; // Get the batch to delete
    const id = batchToDelete.Id; // Assuming each batch has an `Id` property
  
    try {
      // Make the API call to delete the record
      await axios.delete(`https://peapi.microtechsolutions.co.in/php/delete.php?Table=Batchmaster&Id=${id}`);
  
      // Filter out the deleted batch from the state
      const newBatches = batches.filter((_, i) => i !== index);
      setBatches(newBatches);
      
      // Notify the user of success
      toast.success("Batch Deleted Successfully!");
    } catch (error) {
      console.error("Error deleting batch:", error);
      toast.error("Error deleting batch. Please try again.");
    }
  };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!EnquiryModeId) {
      formErrors.EnquiryModeId = "Enquiry Mode is required.";
      isValid = false;
    }

    if (!BranchId) {
      formErrors.BranchId = "Branch is required.";
      isValid = false;
    }

    
    if (!BatchName) {
      formErrors.BatchName = "Batch Name is required.";
      isValid = false;
    }

    if (!EnquiryTypeId) {
      formErrors.EnquiryTypeId = "Enquiry Type is required.";
      isValid = false;
    }

    
    if (!Timing) {
      formErrors.Timing = "Timing is required.";
      isValid = false;
    }



   
    setErrors(formErrors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    const data = { 
      ModeId: EnquiryModeId,
      BranchId: BranchId,
      BatchName: BatchName,
      TypeId: EnquiryTypeId,
      Timing : Timing,
      Fees:Fees
     };
  
    // Check if editing and set the appropriate URL
    const url = isEditing
      ? "https://peapi.microtechsolutions.co.in/php/setbatchmst.php"
      : "https://peapi.microtechsolutions.co.in/php/postbatchmst.php";
  
    // Only add Id when editing
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Batch updated successfully!');
      } else {
        toast.success('Batch added successfully!');
      }
      resetForm();
      fetchBatch(); // Refresh the list after submit
    } catch (error) {
      console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
 

   const columns = useMemo(() => [
    {
      accessorKey: 'SrNo',
      header: "Sr.No",
      size: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "BatchName",
      header: "Batch Name",
      size: 50,
    },
   
    {
      accessorKey: "actions",
      header: "Actions",
      size: 150,
      Cell: ({ row }) => (
        <div>
         <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#012144",
                color: "white",
                marginRight: "5px", 
              }}>Edit 
            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
        </div>
      ),
    },
  ], [batches]);

  const table = useMaterialReactTable({ columns, data: batches,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });
   
 
   
 

  return (



    <div className="batch-container">
    <h1>Batch</h1>
    <div className="batchtable-master">

   
      <div className="batchtable1-master">
      <Button
          onClick={handleNewClick}
          style={{ color: "#FFFF",display:'flex',
             fontWeight: "700", background:'#012144', width:'15%', justifyItems:'left' }}
        >
          New
        </Button>
        <div className="batchtable-container">
       
          <MaterialReactTable table={table} />
        </div>
      </div>

      {isModalOpen && <div className="batch-overlay" onClick={() => setIsModalOpen(false)} />}

      <Modal open={isModalOpen}>
        <div className="batch-modal">
          <h1
            style={{
              textAlign: "center",
              fontWeight: "500",
              margin: "2px",
            }}
          >
            {editingIndex >= 0 ? "Edit Batch " : "Add Batch "}
          </h1>

          <form>


          <div className="batch-form">
        

          
           

            <div>
              <label className="batch-label">
                 Mode Id:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="EnquiryModeId"
                   name="EnquiryModeId"
                   value={enquiryModeOptions.find((option) => option.value === EnquiryModeId)}
                   onChange={(option) => setEnquiryModeId(option.value)}
                   options={enquiryModeOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.EnquiryModeId && <b className="error-text">{errors.EnquiryModeId}</b>}
                  </div>
              </div>
            </div>


            <div>
              <label className="batch-label">
                Branch:<b className="required">*</b>
              </label>
              <div>
              <Select
                   id="BranchId"
                   name="BranchId"
                   value={branchOptions.find((option) => option.value === BranchId)}
                   onChange={(option) => setBranchId(option.value)}
                   options={branchOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.BranchId && <b className="error-text">{errors.BranchId}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="batch-label">
                Batch Name:<b className="required">*</b>
              </label>
              <div>
              <input
type="text" id="BatchName" name="BatchName" value={BatchName}
                  onChange={(e) => setBatchName(e.target.value)}
                  className="batch-control"
                  placeholder="Enter Batch Name"
                />
                <div>
                    {errors.BatchName && <b className="error-text">{errors.BatchName}</b>}
                  </div>
              </div>
            </div>

            <div>
              <label className="batch-label">
                Type Id:<b className="required">*</b>
              </label>{" "}
              <div>
              <Select
                   id="EnquiryTypeId"
                   name="EnquiryTypeId"
                   value={enquirytypeOptions.find((option) => option.value === EnquiryTypeId)}
                   onChange={(option) => setEnquiryTypeId(option.value)}
                   options={enquirytypeOptions}
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                       marginTop: "10px",
                       borderRadius: "4px",
                       border: "1px solid rgb(223, 222, 222)", marginBottom: '5px'
                     }),
                   }}
                   placeholder="Select Id"
                   />

<div>
                    {errors.EnquiryTypeId && <b className="error-text">{errors.EnquiryTypeId}</b>}
                  </div>
              </div>
            </div>

            
            <div>
              <label className="batch-label">
                Timing:<b className="required">*</b>
              </label>{" "}
              <div>
              <input
                   id="Timing"
                   name="Timing"
                   value={Timing}
                   onChange={(e) => setTiming(e.target.value)}
                 className="batch-control"
                   placeholder="Enter Timing"
                   />

<div>
                    {errors.Timing && <b className="error-text">{errors.Timing}</b>}
                  </div>
              </div>
            </div>
           
            <div>
              <label className="batch-label"> 
                Fees:<b className="required">*</b>
              </label>{" "}
              <div>
                <input
type="text" id="Fees" name="Fees" value={Fees}
                  onChange={(e) => setFees(e.target.value)}
                  className="batch-control"
                  placeholder="Enter Fees"
                />

<div>
                    {errors.Fee && <b className="error-text">{errors.Fee}</b>}
                  </div>
              </div>
            </div>
           
           
          
        </div></form>
          <div className="batch-btn-container">
            <Button
              type="submit"
              style={{
                background: "#012144",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {editingIndex >= 0 ? "Update" : "Save"}
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                background: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
    <ToastContainer />

    <footer className="footer">
      <small>&copy; Microtech, 2023. All rights reserved.</small>
    </footer>
  </div>


  );
}

export default Batch;
