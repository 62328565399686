import React from "react";
import "./Navbar.css"; // Add custom CSS for Navbar

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <h3 className="navbar-title">Welcome To Anaya's Chess Club</h3>
      </div>
    </nav>
  );
};

export default Navbar;
