import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./Batchlist.css";
import { ToastContainer } from "react-toastify";

function BatchList() {
  const [data, setData] = useState([]);

  // Fetch batch list
  const fetchBatchlist = async () => {
    try {
      const response = await axios.get(
        "https://peapi.microtechsolutions.co.in/php/gettable.php?Table=Batchmaster"
      );
      console.log(response.data.length, "response of batchlist");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching batch list:", error);
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    fetchBatchlist();
  }, []);



  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "SrNo",
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "BatchName",
        header: "Batch Name",
        size: 50,
      },

      {
        accessorKey: "Timing",
        header: "Timing",
        size: 50,
      },

      {
        accessorKey: "Fees",
        header: "Fees",
        size: 50,
      },
      
    ],
    []
  );

  const table = useMaterialReactTable({ columns, data: data,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
   });

  return (
    <div className="batchlist-container">
      <h1>BatchList</h1>

      <div className="batchlisttable-container">
        <div className="batchtable">
          <MaterialReactTable table={table} />{" "}
        </div>
      </div>
      <ToastContainer/>
      <footer className="footer">
        <small>&copy; Microtech, 2023. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default BatchList;
